import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Navbar from "../components/page/Navbar";

import Footer from "../components/page/Footer";
import SideBar from "../components/page/SideBar";

import { api } from "../api/api.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faBullseye, faChevronUp, faChevronDown, faAnglesLeft, faChevronLeft, faChevronRight, faAnglesRight, faBars } from "@fortawesome/free-solid-svg-icons";
import { Button, Collapse } from 'react-bootstrap';

import { isFuture, getToday, incrementDate } from "../utils/theDateHelper.js";

import HomeTasks from '../components/page/HomeTasks';
import HomeHabits from '../components/page/HomeHabits';

const LOCAL_STORAGE_KEYS = {
    MOTIVATION_MESSAGE: 'motivationMessage',
    LAST_MESSAGE_DATE: 'lastMessageDate',
};

const BUTTON_LABELS = {
    TODAY: 'Today',
};

const Loading = () => <div>Loading...</div>;
const Error = ({ message }) => <div>Error: {message}</div>;

const Home = ({ user, focusAreas = [], challenges = [] }) => {

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 STATE VARIABLES 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const [allTasks, setAllTasks] = useState([]);
    const [incompleteTasks, setIncompleteTasks] = useState([]);
    const [completeTasks, setCompleteTasks] = useState([]);
    const [dateTasks, setDateTasks] = useState([]);

    const [motivationalMessage, setMotivationalMessage] = useState("");
    const [motivationLoading, setMotivationLoading] = useState(false);
    const [motivationError, setMotivationError] = useState(null);

    const [isMotivationOpen, setMotivationOpen] = useState(true);
    const [isHabitsOpen, setHabitsOpen] = useState(true);
    const [isTasksOpen, setTasksOpen] = useState(true);

    const [workDay, setWorkDay] = useState(() => getToday());
    const [allHabits, setAllHabits] = useState([]);
    const [incompleteHabits, setIncompleteHabits] = useState([]);
    const [completeHabits, setCompleteHabits] = useState([]);
    const [habitsLoading, setHabitsLoading] = useState(false);
    const [habitsError, setHabitsError] = useState(null);

    const [tasksLoading, setTasksLoading] = useState(false);
    const [tasksError, setTasksError] = useState(null);

    // Navigation removed to fix infinite loop

    const log = (message) => {
        if (process.env.NODE_ENV === 'development') {
            console.log(message);
        }
    };

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 GENERAL FUNCTIONS 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const getMotivationalMessage = useCallback(async () => {
        setMotivationLoading(true);
        setMotivationError(null);
        try {
            const res = await api.post(`/api/ai/runmotivationalprompt`, {
                focusAreas: focusAreas,
                challenges: challenges
            });
            const data = res.data.data;
            setMotivationalMessage(data.motivation);
            localStorage.setItem(LOCAL_STORAGE_KEYS.LAST_MESSAGE_DATE, getToday());
            localStorage.setItem(LOCAL_STORAGE_KEYS.MOTIVATION_MESSAGE, data.motivation);
        } catch (error) {
            setMotivationError("Error fetching motivational message");
        } finally {
            setMotivationLoading(false);
        }
    }, [focusAreas, challenges]);

    // Load initial data - split into separate effects to avoid infinite loops

    // Effect 1: Load tasks when user or workDay changes
    useEffect(() => {
        if (!user || !user._id) return;

        const loadTasks = async () => {
            setTasksLoading(true);
            setTasksError(null);
            try {
                const response = await api.get(`/api/user/populatetasks/${user._id}`);
                const tasks = response.data;
                setAllTasks(tasks);

                // Sort tasks into appropriate categories
                setIncompleteTasks(tasks.filter(task => !task.complete));
                setCompleteTasks(tasks.filter(task => task.complete));
                setDateTasks(tasks.filter(task => task.complete && task.completedDate === workDay));
            } catch (error) {
                setTasksError("Error retrieving tasks");
            } finally {
                setTasksLoading(false);
            }
        };

        loadTasks();
    }, [user, workDay]);

    // Effect 2: Load habits when user or workDay changes
    useEffect(() => {
        if (!user || !user._id) return;

        const loadHabits = async () => {
            setHabitsLoading(true);
            setHabitsError(null);
            try {
                const response = await api.get(`/api/user/populatehabits/${user._id}`);
                const habits = response.data;
                setAllHabits(habits);

                // Sort habits into appropriate categories
                const complete = habits.filter(habit => {
                    return habit.completionHistory.some(history =>
                        history.date === workDay && history.completed
                    );
                });

                const incomplete = habits.filter(habit =>
                    !habit.completionHistory.some(history =>
                        history.date === workDay && history.completed
                    )
                );

                setCompleteHabits(complete);
                setIncompleteHabits(incomplete);
            } catch (error) {
                setHabitsError("Error retrieving habits");
            } finally {
                setHabitsLoading(false);
            }
        };

        loadHabits();
    }, [user, workDay]);

    // Effect 3: Update derived task states when allTasks changes
    useEffect(() => {
        if (allTasks.length > 0) {
            // Sort tasks into appropriate categories
            setIncompleteTasks(allTasks.filter(task => !task.complete));
            setCompleteTasks(allTasks.filter(task => task.complete));
            setDateTasks(allTasks.filter(task => task.complete && task.completedDate === workDay));
        }
    }, [allTasks, workDay]);

    // Effect 4: Load motivational message when user changes
    useEffect(() => {
        if (!user || !user._id) return;

        const loadMotivationalMessage = async () => {
            const storedMessage = localStorage.getItem(LOCAL_STORAGE_KEYS.MOTIVATION_MESSAGE);
            const storedDate = localStorage.getItem(LOCAL_STORAGE_KEYS.LAST_MESSAGE_DATE);

            if (storedMessage && storedDate === getToday()) {
                setMotivationalMessage(storedMessage);
            } else {
                await getMotivationalMessage();
            }
        };

        loadMotivationalMessage();
    }, [user, getMotivationalMessage]);

    const toggleMotivation = () => {
        setMotivationOpen(!isMotivationOpen);
    };

    const toggleHabits = () => {
        setHabitsOpen(!isHabitsOpen);
    };

    const toggleTasks = () => {
        setTasksOpen(!isTasksOpen);
    };

    const updateWorkDay = (increment = 0) => {
        log("Home.jsx in updateWorkDay");
        const updatedWorkDay = incrementDate(workDay, increment);
        setWorkDay(updatedWorkDay);

        // The sorting will happen automatically via the useEffect hooks
        // when workDay changes
    };

    const resetWorkDay = () => {
        log("Home.jsx in resetWorkDay");
        setWorkDay(getToday());
        // The sorting will happen automatically via the useEffects
    };

    const toggleHabitComplete = async (user2habitID, habitWorkDay, newCompleteState) => {
        log("Home.jsx in toggleHabitComplete");
        try {
            const habit = incompleteHabits.find(habit => habit._id === user2habitID) || completeHabits.find(habit => habit._id === user2habitID);
            // Use workDay from the component instead of creating a new one
            const currentWorkDay = workDay;

            if (habit) {
                // Create a deep copy of the habit to avoid state mutation issues
                const updatedHabit = JSON.parse(JSON.stringify(habit));

                if (newCompleteState) {
                    // Mark as complete - add to completion history if not already there
                    const alreadyCompleted = updatedHabit.completionHistory.some(entry =>
                        entry.date === currentWorkDay && entry.completed
                    );

                    if (!alreadyCompleted) {
                        updatedHabit.completionHistory.push({ date: currentWorkDay, completed: true });
                    }

                    // Update state directly
                    const updatedCompleteHabits = [...completeHabits.filter(h => h._id !== user2habitID), updatedHabit];
                    const updatedIncompleteHabits = incompleteHabits.filter(h => h._id !== user2habitID);
                    setCompleteHabits(updatedCompleteHabits);
                    setIncompleteHabits(updatedIncompleteHabits);
                } else {
                    // Mark as incomplete - remove from completion history
                    updatedHabit.completionHistory = updatedHabit.completionHistory.filter(
                        entry => entry.date !== currentWorkDay
                    );

                    // Update state directly
                    const updatedCompleteHabits = completeHabits.filter(h => h._id !== user2habitID);
                    const updatedIncompleteHabits = [...incompleteHabits.filter(h => h._id !== user2habitID), updatedHabit];
                    setCompleteHabits(updatedCompleteHabits);
                    setIncompleteHabits(updatedIncompleteHabits);
                }

                // Update the allHabits array to trigger useEffect
                const updatedAllHabits = allHabits.map(h => h._id === user2habitID ? updatedHabit : h);
                setAllHabits(updatedAllHabits);

                // Save to API
                await api.post("/api/user2habit/handlehabit", {
                    id: user2habitID,
                    completed: newCompleteState,
                    workDay: currentWorkDay
                });
            }
        } catch (error) {
            console.error("Error in toggleHabitComplete:", error);
        }
    };

    const updateHabitOrder = async (reorderedHabits) => {
        setIncompleteHabits(reorderedHabits);
        try {
            await api.put("/api/user/updateHabitorder", { habits: reorderedHabits }, { timeout: 5000 });
        } catch (error) {
            console.error('Error updating habit order: ', error);
        }
    };

    const toggleTaskComplete = async (taskID, newCompleteState) => {
        const updatedTasks = allTasks.map(task =>
            task._id === taskID
                ? { ...task, complete: newCompleteState, completedDate: newCompleteState ? getToday() : null }
                : task
        );

        setAllTasks(updatedTasks);
        // Sorting will happen automatically via the useEffect

        try {
            const fieldsToUpdate = { complete: newCompleteState };
            if (newCompleteState) {
                fieldsToUpdate.completedDate = getToday();
            }
            await api.put(`/api/task/${taskID}`, fieldsToUpdate);
        } catch (error) {
            console.error('Error updating task complete: ', error);
        }
    };

    const addTask = async (taskText) => {
        // log("Home.jsx in addTask");
        try {
            const res = await api.post(`/api/task/create`, { text: taskText, user: user._id });
            setAllTasks([...allTasks, res.data]);
            // Sorting will happen automatically via the useEffect
        } catch (err) {
            console.error(err);
        }
    };

    const deleteTask = async (id) => {
        // log("Home.jsx in deleteTask");
        try {
            await api.delete(`/api/task/${id}`);

            const updatedTasks = allTasks.filter((item) => item._id !== id);
            setAllTasks(updatedTasks); // Set the updated tasks
            // Sorting will happen automatically via the useEffect
        } catch (err) {
            console.error('Error deleting Task:', err);
        }
    };

    const updateTaskOrder = async (reorderedTasks) => {
        // log("Home.jsx in updateTaskOrder");
        try {
            setAllTasks(reorderedTasks);
            // Sorting will happen automatically via the useEffect
            await api.put("/api/user/updatetaskorder", { tasks: reorderedTasks });
        } catch (error) {
            console.error("Error updating to-do order:", error);
        }
    };

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Home - Goals & Habits Management</title>
                <meta name="description" content="Manage your home page with goals and habits." />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar className="navbar-class" />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0 bg-light">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'}`}>
                        <SideBar className="sidebar-class" />
                    </div>
                </div>
                <div className="col flex-grow-1 px-4 pt-4">
                    <section className="mb-4">
                        <div className="block block-primary-header">
                            <div className="block-header">
                                <div className="d-flex align-items-center gap-3">
                                    <button
                                        className="btn btn-light btn-square-rounded shadow-sm"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#sidebar">
                                        <FontAwesomeIcon icon={faBars} />
                                    </button>
                                    <h2 className="mb-0 fw-bold">🛠️ Work</h2>
                                </div>
                            </div>
                            <div className="block-body">
                                <p className="mb-0">Welcome back, {user ? user.username : 'User'}! Track your progress and build better habits</p>
                            </div>
                        </div>
                    </section>

                    {user ? (
                        <>
                            <div>
                                {Array.isArray(focusAreas) && focusAreas.length === 0 && (
                                    <div>
                                        You have not set any focus areas. Please <Link to="/profile">update your profile</Link> to set them.
                                    </div>
                                )}
                                {Array.isArray(challenges) && challenges.length === 0 && (
                                    <div>
                                        You have not set any challenges. Please <Link to="/profile">update your profile</Link> to set them.
                                    </div>
                                )}

                                <section className="mb-4">
                                    <div className="block block-primary">
                                        <div className="block-header">
                                            <h4 className="mb-0 fw-bold">✨ Daily Motivation</h4>
                                            <Button
                                                onClick={toggleMotivation}
                                                aria-controls="motivation-content"
                                                aria-expanded={isMotivationOpen}
                                                variant="link"
                                                className="text-decoration-none p-0"
                                            >
                                                <FontAwesomeIcon icon={isMotivationOpen ? faChevronUp : faChevronDown} />
                                            </Button>
                                        </div>
                                        <Collapse in={isMotivationOpen} className="collapsible-section">
                                            <div className="block-body">
                                                {motivationLoading ? (
                                                    <Loading />
                                                ) : motivationError ? (
                                                    <Error message={motivationError} />
                                                ) : (
                                                    <blockquote className="blockquote mb-0">
                                                        <p className="lead font-italic text-muted">
                                                            {motivationalMessage}
                                                        </p>
                                                    </blockquote>
                                                )}
                                            </div>
                                        </Collapse>
                                    </div>
                                </section>

                                <section className="mb-4 d-flex justify-content-center">
                                    <div className="block block-sm block-primary mx-auto">
                                        <div className="block-header">
                                            <h5 className="mb-0 fw-bold">🗓️ Date Selection</h5>
                                        </div>
                                        <div className="block-body p-3">
                                            <div className="d-flex flex-column align-items-center">
                                                <div className="d-flex align-items-center justify-content-center gap-2 mb-3">
                                                    <button 
                                                        className="button button--sm button--icon-only button--primary"
                                                        onClick={() => updateWorkDay(-7)}
                                                    >
                                                        <FontAwesomeIcon icon={faAnglesLeft} />
                                                    </button>
                                                    <button 
                                                        className="button button--sm button--icon-only button--primary"
                                                        onClick={() => updateWorkDay(-1)}
                                                    >
                                                        <FontAwesomeIcon icon={faChevronLeft} />
                                                    </button>
                                                    <div className="fw-bold px-4 py-2 bg-light rounded-3 mx-2">
                                                        {workDay}
                                                    </div>
                                                    <button 
                                                        className="button button--sm button--icon-only button--primary"
                                                        onClick={() => updateWorkDay(1)} disabled={isFuture(workDay, 1)}
                                                    >
                                                        <FontAwesomeIcon icon={faChevronRight} />
                                                    </button>
                                                    <button 
                                                        className="button button--sm button--icon-only button--primary"
                                                        onClick={() => updateWorkDay(7)} disabled={isFuture(workDay, 7)}
                                                    >
                                                        <FontAwesomeIcon icon={faAnglesRight} />
                                                    </button>
                                                </div>
                                                <button 
                                                    className="button button--primary"
                                                    onClick={() => resetWorkDay()}
                                                >
                                                    {BUTTON_LABELS.TODAY}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <div className="row d-flex align-items-start">
                                    <div className="col-12 col-lg-6 mb-4">
                                        <div className="block block-primary h-100">
                                            <div className="block-header">
                                                <h4 className="mb-0 fw-bold">🌱 Habits</h4>
                                                <Button
                                                    onClick={toggleHabits}
                                                    aria-controls="habits-content"
                                                    aria-expanded={isHabitsOpen}
                                                    variant="link"
                                                    className="text-decoration-none p-0"
                                                    style={{ minWidth: '30px', textAlign: 'center' }}
                                                >
                                                    <FontAwesomeIcon icon={isHabitsOpen ? faChevronUp : faChevronDown} />
                                                </Button>
                                            </div>
                                            <Collapse in={isHabitsOpen} className="collapsible-section">
                                                <div className="block-body">
                                                    {habitsLoading ? (
                                                        <Loading />
                                                    ) : habitsError ? (
                                                        <Error message={habitsError} />
                                                    ) : (
                                                        <HomeHabits
                                                            className="habits-class"
                                                            completeHabits={completeHabits}
                                                            incompleteHabits={incompleteHabits}
                                                            workDay={workDay}
                                                            updateHabitOrder={updateHabitOrder}
                                                            toggleHabitComplete={toggleHabitComplete}
                                                        />
                                                    )}
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6 mb-4">
                                        <div className="block block-primary h-100">
                                            <div className="block-header">
                                                <h4 className="mb-0 fw-bold">📋 Tasks</h4>
                                                <Button
                                                    onClick={toggleTasks}
                                                    aria-controls="tasks-content"
                                                    aria-expanded={isTasksOpen}
                                                    variant="link"
                                                    className="text-decoration-none p-0"
                                                    style={{ minWidth: '30px', textAlign: 'center' }}
                                                >
                                                    <FontAwesomeIcon icon={isTasksOpen ? faChevronUp : faChevronDown} />
                                                </Button>
                                            </div>
                                            <Collapse in={isTasksOpen} className="collapsible-section">
                                                <div className="block-body">
                                                    {tasksLoading ? (
                                                        <Loading />
                                                    ) : tasksError ? (
                                                        <Error message={tasksError} />
                                                    ) : (
                                                        <HomeTasks
                                                            className="tasks-class"
                                                            workDay={workDay}
                                                            incompleteTasks={incompleteTasks}
                                                            completeTasks={completeTasks}
                                                            dateTasks={dateTasks}
                                                            addTask={addTask}
                                                            deleteTask={deleteTask}
                                                            toggleTaskComplete={toggleTaskComplete}
                                                            updateTaskOrder={updateTaskOrder}
                                                        />
                                                    )}
                                                </div>
                                            </Collapse>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                    ) : (
                        <div className="container-fluid px-4 py-5">
                            {/* Hero Section */}
                            <div className="row align-items-center py-5 bg-light rounded-4 shadow-sm mb-5">
                                <div className="col-md-8 col-lg-7 mx-auto text-center py-5">
                                    <h1 className="display-4 fw-bold mb-3">Welcome to AImbition</h1>
                                    <p className="lead fs-4 mb-4">
                                        Your AI-powered companion for achieving personal and professional goals through habit building and smart tracking.
                                    </p>
                                    <hr className="my-4 w-50 mx-auto" />
                                    <p className="mb-4 fs-5">Join thousands of users who are transforming their lives with our intelligent goal-setting and habit-tracking platform.</p>
                                    <div className="d-grid gap-3 d-sm-flex justify-content-sm-center mb-3">
                                        <Link to="/register" className="btn btn-blue btn-lg px-4 py-2 fw-medium">
                                            Get Started
                                        </Link>
                                        <Link to="/login" className="btn btn-outline-blue btn-lg px-4 py-2 fw-medium">
                                            Log In
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            {/* Feature Cards */}
                            <div className="row text-center mb-5">
                                <div className="col-12">
                                    <h2 className="display-6 fw-bold mb-5">Unlock Your Potential</h2>
                                </div>
                            </div>

                            <div className="row row-cols-1 row-cols-md-3 g-4 mb-5">
                                <div className="col">
                                    <div className="card h-100 shadow-sm rounded-4 border-0 hover-lift">
                                        <div className="card-header bg-white border-0 pt-4">
                                            <h3 className="h4 mb-0 fw-bold text-center">Smart Goal Setting</h3>
                                        </div>
                                        <div className="card-body d-flex flex-column">
                                            <div className="text-center mb-3">
                                                <div className="feature-icon-container rounded-circle bg-primary-light d-inline-flex p-3 mb-3">
                                                    <FontAwesomeIcon icon={faBullseye} size="2x" style={{ color: 'var(--primary-blue)' }} />
                                                </div>
                                                <p className="card-text px-3 mb-4">
                                                    Define meaningful goals with AI assistance, track your progress, and celebrate your achievements along the way.
                                                </p>
                                                <div className="mt-auto">
                                                    <Link to="/register" className="btn btn-outline-blue w-100 fw-medium">
                                                        Set Your First Goal
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card h-100 shadow-sm rounded-4 border-0 hover-lift">
                                        <div className="card-header bg-white border-0 pt-4">
                                            <h3 className="h4 mb-0 fw-bold text-center">Habit Building</h3>
                                        </div>
                                        <div className="card-body d-flex flex-column">
                                            <div className="text-center mb-3">
                                                <div className="feature-icon-container rounded-circle bg-primary-light d-inline-flex p-3 mb-3">
                                                    <FontAwesomeIcon icon={faCheck} size="2x" style={{ color: 'var(--primary-blue)' }} />
                                                </div>
                                                <p className="card-text px-3 mb-4">
                                                    Develop powerful habits that support your goals with personalized recommendations and streak tracking.
                                                </p>
                                                <div className="mt-auto">
                                                    <Link to="/register" className="btn btn-outline-blue w-100 fw-medium">
                                                        Build Better Habits
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card h-100 shadow-sm rounded-4 border-0 hover-lift">
                                        <div className="card-header bg-white border-0 pt-4">
                                            <h3 className="h4 mb-0 fw-bold text-center">AI-Powered Insights</h3>
                                        </div>
                                        <div className="card-body d-flex flex-column">
                                            <div className="text-center mb-3">
                                                <div className="feature-icon-container rounded-circle bg-primary-light d-inline-flex p-3 mb-3">
                                                    <FontAwesomeIcon icon={faChevronUp} size="2x" style={{ color: 'var(--primary-blue)' }} />
                                                </div>
                                                <p className="card-text px-3 mb-4">
                                                    Get personalized recommendations and actionable insights to optimize your progress and overcome challenges.
                                                </p>
                                                <div className="mt-auto">
                                                    <Link to="/register" className="btn btn-outline-blue w-100 fw-medium">
                                                        Discover Insights
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Testimonial Section */}
                            <div className="row py-5 my-5 bg-light rounded-4 shadow-sm">
                                <div className="col-12 text-center mb-4">
                                    <h3 className="display-6 fw-bold mb-5">What Our Users Say</h3>
                                </div>
                                <div className="row row-cols-1 row-cols-md-3 g-4 px-4">
                                    <div className="col">
                                        <div className="card h-100 shadow-sm rounded-4 border-0">
                                            <div className="card-body p-4">
                                                <p className="fst-italic mb-4">
                                                    "AImbition helped me establish a consistent workout routine and finally achieve my fitness goals after years of trying."
                                                </p>
                                                <p className="text-end mb-0 fw-bold">
                                                    — Sarah K.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card h-100 shadow-sm rounded-4 border-0">
                                            <div className="card-body p-4">
                                                <p className="fst-italic mb-4">
                                                    "The AI recommendations were spot-on! I've made more progress in my career in 3 months than I did all last year."
                                                </p>
                                                <p className="text-end mb-0 fw-bold">
                                                    — Michael T.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card h-100 shadow-sm rounded-4 border-0">
                                            <div className="card-body p-4">
                                                <p className="fst-italic mb-4">
                                                    "I love how the app helps me break down my big goals into manageable daily habits. It's transformed how I approach challenges."
                                                </p>
                                                <p className="text-end mb-0 fw-bold">
                                                    — Alex R.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Final CTA */}
                            <div className="row py-5 text-center">
                                <div className="col-md-8 mx-auto">
                                    <h3 className="display-6 fw-bold mb-3">Ready to transform your life?</h3>
                                    <p className="lead fs-4 mb-4">
                                        Join AImbition today and start your journey toward achieving your goals.
                                    </p>
                                    <Link to="/register" className="btn btn-blue btn-lg px-5 py-3 fw-medium">
                                        Get Started Now
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col bg-white border-top border-dark">
                    <Footer className="footer-class" />
                </div>
            </div>
        </div>
    );
};

export default Home;
