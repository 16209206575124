import '../styles/styles.css';
import { useState, useContext, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Footer from "../components/page/Footer";
import SideBar from "../components/page/SideBar";
import { useTutorial } from '../contexts/TutorialContext';
import { api } from "../api/api.js";
import { AuthContext } from "../context/AuthContext";
import { faCheck, faBars, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { GOAL_CATEGORIES } from '../utils/goalOptions';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import UserGoals from '../components/page/UserGoals';
import CustomGoalForm from '../components/page/CustomGoalForm.jsx';
import AIGoals from '../components/page/AIGoals';
import PopularGoals from '../components/page/PopularGoals';

const Goals = () => {

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 STATE VARIABLES 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const { user } = useContext(AuthContext);
    const [userGoals, setUserGoals] = useState([]);
    const [allGoals, setAllGoals] = useState([]);
    const [aiGoals, setAiGoals] = useState([]);

    const [allGoalsLoading, setAllGoalsLoading] = useState(false);
    const [aiGoalsLoading, setAiGoalsLoading] = useState(false);
    const [userGoalsLoading, setUserGoalsLoading] = useState(false);

    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showPendingModal, setShowPendingModal] = useState(false);
    const [showProceedModal, setShowProceedModal] = useState(false);

    const [pendingGoal, setPendingGoal] = useState('');

    const [category, setCategory] = useState("");
    const [error, setError] = useState("");
    const [showTutorialModal, setShowTutorialModal] = useState(false);

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 Tutorial Functions 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const { tutorialState } = useTutorial();
    const { currentStep } = tutorialState;

    useEffect(() => {
        if (currentStep === 'goals') {
            setShowTutorialModal(true);
        } else {
            setShowTutorialModal(false);
        }
    }, [currentStep]);

    const handleTutorialComplete = () => {
        setShowTutorialModal(false);
        navigate('/', { state: { tutorialComplete: true } });
    };

    const TutorialModal = ({ show, onHide }) => (
        <Modal show={show} onHide={onHide} className="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Creating Your First Goal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="tutorial-step">
                    <h5>Let's create a meaningful goal!</h5>
                    <p>You have two options:</p>
                    <ol>
                        <li>Use AI to generate personalized goal suggestions</li>
                        <li>Create a custom goal that's specific to you</li>
                    </ol>
                    <p>After creating your goal, we'll help you select supporting habits to achieve it.</p>
                    <div className="mt-3 p-3 bg-light rounded">
                        <p className="mb-2"><strong>💡 Quick Tip:</strong></p>
                        <ul className="mb-0">
                            <li>First, select a category using the category selector at the top</li>
                            <li>Then, either create a custom goal or choose an AI-suggested goal</li>
                            <li>Finally, confirm your goal to proceed to selecting habits</li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleTutorialComplete}>Skip Tutorial</Button>
                <Button variant="primary" onClick={handleTutorialComplete}>Let's Create a Goal</Button>
            </Modal.Footer>
        </Modal>
    );

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 General Functions 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const CustomToastContent = ({ message }) => (
        <div className="custom-toast-content">
            <FontAwesomeIcon icon={faCheck} className="custom-toast-check" />
            <p>{message}</p>
        </div>
    );

    const showToast = useCallback((message) => {
        toast(<CustomToastContent message={message} />, {
            position: toast.POSITION.BOTTOM_RIGHT,
            progressClassName: 'custom-toast-progress',
        });
    }, []);

    const checkIfLinked = (id) => {
        if (!Array.isArray(userGoals)) {
            return true;
        }
        return userGoals.indexOf(id) === -1;
    };

    const navigate = useNavigate();

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 Goal Functions 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const retrieveUserGoals = useCallback(async () => {

        setUserGoalsLoading(true);
        try {
            const userGoalsRes = await api.get(`/api/user/populategoals/${user._id}`);
            setUserGoals(userGoalsRes.data || []);
        } catch (err) {
            setError(err.message);
            toast.error('Error fetching user goals: ' + err.message);
        } finally {
            setUserGoalsLoading(false);
        }
    }, [user]);

    const retrieveAIGoals = useCallback(async (category) => {
        setAiGoalsLoading(true);
        try {
            const res = await api.post(`/api/ai/rungoalprompt`, { category });
            setAiGoals(res.data.data.relatedGoals || []);
        } catch (err) {
            showToast('Error fetching AI goals: ' + err.message);
        } finally {
            setAiGoalsLoading(false);
        }
    }, [showToast]);

    const retrieveCategoryGoals = useCallback(async (category) => {
        setAllGoalsLoading(true);
        try {
            const endpoint = category === 'All'
                ? '/api/goal'
                : `/api/goal/category/${category}`;

            const predefinedGoalsRes = await api.get(endpoint);
            setAllGoals(predefinedGoalsRes.data);
        } catch (err) {
            console.error("Error fetching category goals:", err);
            setError(err.message);
        } finally {
            setAllGoalsLoading(false);
        }
    }, []);

    const fetchGoals = useCallback(async (category) => {
        if (user) {
            await retrieveUserGoals();
            await retrieveAIGoals(category);
        }
        await retrieveCategoryGoals(category);
    }, [user, retrieveUserGoals, retrieveAIGoals, retrieveCategoryGoals]);

    const handleCategorySelect = (selectedCategory) => {
        setCategory(selectedCategory);
        setShowCategoryModal(false);
        setAiGoalsLoading(true);
        retrieveCategoryGoals(selectedCategory);
        if (user) {
            retrieveAIGoals(selectedCategory);
            retrieveUserGoals(selectedCategory);
        }
    };

    useEffect(() => {
        if (user && user.focusAreas && user.focusAreas.length > 0) {
            const selectedCategory = user.focusAreas[0];
            setCategory(selectedCategory);
            fetchGoals(selectedCategory);
        } else {
            // Fetch category goals even if no user is present
            retrieveCategoryGoals('All');
        }
    }, [user, fetchGoals, retrieveCategoryGoals]);

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 Modal Management Functions 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const handleCloseModal = () => {
        setShowCategoryModal(false);
        document.getElementById('sidebar').classList.remove('show');
    };

    const handleShowConfirmModal = (goal) => {
        setPendingGoal(goal);
        setShowConfirmModal(true);
    };



    const updateGoalStatus = async (user2goalID, newGoalStatus) => {
        setUserGoals(prevGoals => prevGoals.map(goal => {
            if (goal._id === user2goalID) {
                return { ...goal, status: newGoalStatus }; // Use spread operator to return a new object
            }
            return goal;
        }));

        try {
            await api.put(`/api/user2goal/${user2goalID}`, { status: newGoalStatus });
        } catch (err) {
            console.error(err);
        }
    };

    const removeGoal = async (goalId) => {
        try {
            await api.delete(`/api/user2goal/${goalId}`);
            setUserGoals(prevGoals => prevGoals.filter(goal => goal._id !== goalId));
        } catch (error) {
            console.error(error);
        }
    };



    const handleConfirm = () => {
        handleAddGoal(pendingGoal);
        setShowConfirmModal(false);
    };

    const handleAddGoal = async (goal) => {
        setShowPendingModal(true); // Show the pending modal
        try {
            // Add a small delay to ensure the modal is visible
            await new Promise(resolve => setTimeout(resolve, 300));

            const response = await api.post(`/api/goal/createuser`, {
                headline: goal,
                text: goal,
                description: goal,
                category: category,
                user: user._id,
            });
            console.log('Goal added successfully!', response.data);

            // Keep the pending modal visible for a consistent time
            await new Promise(resolve => setTimeout(resolve, 1000));

            // Hide pending modal before fetching updated goals to prevent UI flashing
            setShowPendingModal(false);

            // Refresh user goals to show the newly added goal
            await retrieveUserGoals();

            // Show success modal after goals are refreshed
            setShowProceedModal(true);
        } catch (error) {
            console.error('Error adding goal:', error);
            setShowPendingModal(false);
            // Handle error (e.g., show an error message)
        }
    };

    const handleProceedToHabits = () => {
        navigate('/habits', {
            state: {
                fromGoal: true,
                fromTutorial: true, // Always pass tutorial state if we're proceeding from a goal
                goalText: pendingGoal.text || pendingGoal,
                goalCategory: category
            }
        });
        setShowProceedModal(false);
        setShowTutorialModal(false);
    };

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 Modals 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const CategoryModal = ({ show, onHide }) => (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton className="border-0">
                <Modal.Title className="fw-bold">Select a Goal Category</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4 py-3">
                <p className="text-muted mb-3">Choose a category to focus your goals and see relevant suggestions</p>
                <div className="category-grid">
                    {GOAL_CATEGORIES.map(option => (
                        <div
                            key={option.value}
                            onClick={() => handleCategorySelect(option.value)}
                            className="category-card shadow-sm rounded-4 p-3 mb-3 text-center">
                            <div className="category-icon mb-2">
                                {/* You can add icons for each category if available */}
                                <FontAwesomeIcon icon={faLayerGroup} className="fs-3 text-secondary" />
                            </div>
                            <div className="category-label fw-medium">{option.label}</div>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button variant="outline-secondary" onClick={handleCloseModal} className="rounded-3">Cancel</Button>
            </Modal.Footer>
        </Modal>
    );

    const ConfirmModal = ({ show, onHide, onConfirm, goal }) => (
        <Modal show={show} onHide={onHide} className="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Confirm Action</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to add "{goal}"?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={onConfirm}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );

    const PendingModal = ({ show, onHide }) => (
        <Modal show={show} onHide={onHide} className="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Adding Goal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Your goal is being added...</p>
            </Modal.Body>
        </Modal>
    );

    const SuccessModal = ({ show, onHide, goal, onProceed }) => (
        <Modal show={show} onHide={onHide} className="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Goal Added</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Goal "{goal}" has been added! Now let's find some habits.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={onProceed}>Proceed</Button>
            </Modal.Footer>
        </Modal>
    );

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 Modal Functions 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const handleShowCategoryModal = () => {
        setShowCategoryModal(true);
    };

    return (
        <div className="container-fluid">
            <TutorialModal
                show={showTutorialModal}
                onHide={() => setShowTutorialModal(false)}
            />
            <Helmet>
                <title>Choose Goals to Improve Your Life</title>
                <meta name="description" content="Select from a preconfigured list of Goals and Habits" />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0 bg-light">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'}`}>
                        <SideBar />
                    </div>
                </div>
                <div className="col flex-grow-1 px-4 pt-4">
                    <section className="mb-4">
                        <div className="block block-primary-header">
                            <div className="block-header">
                                <div className="d-flex align-items-center gap-3">
                                    <button
                                        className="btn btn-light btn-square-rounded shadow-sm"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#sidebar">
                                        <FontAwesomeIcon icon={faBars} />
                                    </button>
                                    <h2 className="mb-0 fw-bold">🎯 Goals</h2>
                                </div>
                                {user && (
                                    <div
                                        onClick={handleShowCategoryModal}
                                        className="category-selector d-flex align-items-center gap-2 p-2 rounded-3 bg-white shadow-sm">
                                        <FontAwesomeIcon icon={faLayerGroup} className="text-secondary" />
                                        <span className="fw-medium">{category || 'Select Category'}</span>
                                    </div>
                                )}
                            </div>
                            <div className="block-body">
                                <p className="mb-0">Track your progress and achieve what matters most to you</p>
                            </div>
                        </div>
                    </section>

                    <UserGoals
                        user={user}
                        category={category}
                        userGoals={userGoals}
                        loading={userGoalsLoading}
                        updateGoalStatus={updateGoalStatus}
                        removeGoal={removeGoal}
                    />

                    <CustomGoalForm
                        user={user}
                        userGoals={userGoals}
                        loading={userGoalsLoading}
                        error={error}
                        category={category}
                        handleShowConfirmModal={handleShowConfirmModal}
                    />

                    <AIGoals
                        user={user}
                        category={category}
                        aiGoals={aiGoals}
                        loading={aiGoalsLoading}
                        checkIfLinked={checkIfLinked}
                        handleShowConfirmModal={handleShowConfirmModal}
                        handleAddGoal={handleAddGoal}
                    />
                    <PopularGoals
                        user={user}
                        category={category}
                        allGoals={allGoals}
                        loading={allGoalsLoading}
                        checkIfLinked={checkIfLinked}
                        handleAddGoal={handleAddGoal}
                        handleShowConfirmModal={handleShowConfirmModal}
                    />
                    <ConfirmModal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} onConfirm={handleConfirm} goal={pendingGoal} />
                    <PendingModal show={showPendingModal} onHide={() => setShowPendingModal(false)} />
                    <SuccessModal show={showProceedModal} onHide={() => setShowProceedModal(false)} goal={pendingGoal} onProceed={handleProceedToHabits} />
                    <CategoryModal show={showCategoryModal} onHide={() => setShowCategoryModal(false)} />
                </div>
            </div>
            <div className="row">
                <div className="col bg-white border-top border-dark">
                    <Footer />
                </div>
            </div>
        </div >
    );
};

export default Goals;
