// Tutorial steps data
export const tutorialSteps = {
    // Step 1: Welcome to AImbition (command phase only)
    welcome: {
        // Command phase - shown at the top of the screen
        command: {
            title: "Welcome to AImbition!",
            description: "Transform your goals into reality with AImbition's proven system: set meaningful objectives, build supporting habits, and track your progress. In just 5 quick steps, you'll master the tools needed to achieve what matters most to you.",
            exploreAction: "Click Continue to begin the tutorial",
            nextStep: 'navigation'
        }
    },
    
    // Step 2: Explore App Navigation with the Sidebar
    navigation: {
        // Sub-step A: Command phase
        command: {
            title: "Explore App Navigation",
            description: "The sidebar menu provides access to the main sections of AImbition:\n\n1. Work: View your tasks and habits for the day, with motivational messages based on your focus areas.\n\n2. Goals: Create and manage goals organized by categories like Career, Health, and Finance.\n\n3. Habits: Set up recurring activities that support your goals, with options for custom or AI-suggested habits.\n\n4. Profile: Set your focus areas (like Career, Health, Family) and identify personal challenges (like Procrastination or Lack of Time).\n\n👉 ***YOUR TASK:*** Open the sidebar and click on each menu item to explore the different pages!",
            exploreAction: "Click the menu icon (☰) and explore each page. Try clicking on Goals, Habits, Profile, and then back to Work to get familiar with the navigation.",
            nextStep: 'goalCategories'
        },
        // Sub-step B: Explore phase
        explore: {
            title: "App Navigation",
            description: "The sidebar is your gateway to all features in AImbition:",
            sections: [
                { 
                    name: "Work", 
                    description: "Your dashboard with an overview of goals and habits."
                },
                { 
                    name: "Goals", 
                    description: "Create and manage your long-term objectives."
                },
                { 
                    name: "Habits", 
                    description: "Develop daily routines that support your goals."
                },
                { 
                    name: "Profile", 
                    description: "Customize your experience and preferences."
                }
            ]
        }
    },
    
    // Step 3: Understand Goal Categories
    goalCategories: {
        // Sub-step A: Command phase
        command: {
            title: "Understand Goal Categories",
            description: "The Goals page organizes your objectives into meaningful life categories:\n\n1. Career: Professional development, job skills, and work achievements.\n\n2. Family: Parenting, relationships, and quality time with loved ones.\n\n3. Finance: Saving, investing, debt reduction, and financial planning.\n\n4. Fun: Hobbies, travel, recreation, and enjoyment.\n\n5. Health: Physical fitness, nutrition, mental wellbeing, and medical goals.\n\n6. Relationship: Connections with partners, friends, and colleagues.\n\n7. Self-Development: Learning, skills, personal growth, and education.\n\n8. Spiritual: Meditation, mindfulness, faith, and inner peace.\n\n👉 ***YOUR TASK:*** Review the goal categories and think about which areas you want to focus on first!",
            exploreAction: "Look at the different goal categories on the Goals page and identify which ones matter most to you",
            nextStep: 'createGoal'
        },
        // Sub-step B: Explore phase
        explore: {
            title: "Goal Categories",
            description: "AImbition organizes goals into these categories:",
            sections: [
                { 
                    name: "Personal Growth", 
                    description: "Goals related to learning, skills, and self-improvement."
                },
                { 
                    name: "Career & Finance", 
                    description: "Professional objectives and financial milestones."
                },
                { 
                    name: "Health & Wellness", 
                    description: "Physical fitness, nutrition, and mental wellbeing goals."
                },
                { 
                    name: "Relationships", 
                    description: "Goals for improving connections with others."
                }
            ]
        }
    },
    
    // Step 4: Set a Goal and Supporting Habits
    createGoal: {
        // Sub-step A: Command phase
        command: {
            title: "Set a Goal with Supporting Habits",
            description: "Turn your ambitions into achievable goals by following these steps:\n\n1. Create a specific, measurable goal (e.g., 'Run a 5K race' rather than just 'Get fit').\n\n2. Add supporting habits that will help you make daily progress (e.g., 'Run for 20 minutes' or 'Stretch before bed').\n\n3. Track your habit streaks to build consistency and momentum.\n\n👉 ***YOUR TASK:*** Create your first goal and add at least one supporting habit that you can do regularly!",
            exploreAction: "Click the '+' button in the Goals section, create a meaningful goal, then add a supporting habit from the Habits page",
            nextStep: 'updateProfile'
        },
        // Sub-step B: Explore phase
        explore: {
            title: "Creating Effective Goals and Habits",
            description: "Follow these principles for success:",
            sections: [
                { 
                    name: "Specific Goals", 
                    description: "Define clear, measurable objectives with deadlines."
                },
                { 
                    name: "Break It Down", 
                    description: "Divide large goals into smaller milestones."
                },
                { 
                    name: "Supporting Habits", 
                    description: "Create daily or weekly habits that move you toward your goal."
                },
                { 
                    name: "Track Progress", 
                    description: "Regularly check in and adjust your approach as needed."
                }
            ]
        }
    },
    
    // Step 5: Update Focus Areas and Challenges
    updateProfile: {
        // Sub-step A: Command phase
        command: {
            title: "Update Your Profile",
            description: "Personalize AImbition to match your unique situation:\n\n1. Focus Area: Select one life domain (Career, Health, Family, etc.) that is most important to you right now.\n\n2. Personal Challenge: Identify your primary obstacle (Procrastination, No Time, Fear of Failure, etc.).\n\n(Optional) Account Settings: You can also update your username, email, and password if desired.\n\n👉 ***YOUR TASK:*** Select one focus area and one challenge on the Profile page. These will help the app provide more relevant goals and motivational messages.",
            exploreAction: "Navigate to the Profile page and select your focus area and challenge",
            nextStep: 'complete'
        },
        // Sub-step B: Explore phase
        explore: {
            title: "Personalizing Your Experience",
            description: "Your selections help AImbition tailor its recommendations specifically for you:",
            sections: [
                { 
                    name: "Focus Areas", 
                    description: "Identify the life domains most important to you."
                },
                { 
                    name: "Challenges", 
                    description: "Acknowledge obstacles that might hinder your progress."
                },
                { 
                    name: "Preferences", 
                    description: "Set notification preferences and reminder frequency."
                },
                { 
                    name: "Review Schedule", 
                    description: "Choose how often to review your progress."
                }
            ]
        }
    },
    
    // Completion marker (final step with congratulations)
    complete: {
        command: {
            title: "Congratulations! You're Ready to Achieve",
            description: "You've completed the AImbition tutorial and are ready to start your journey!\n\n1. You've learned how to navigate the app\n\n2. You understand how to create goals in different life categories\n\n3. You know how to set up supporting habits for your goals\n\n4. You've customized your profile with focus areas and challenges\n\n👉 ***YOUR NEXT STEP:*** Start by creating a meaningful goal in an area that matters most to you right now. Then add at least one small, daily habit to support it.",
            exploreAction: "Start Using AImbition Now",
            nextStep: null
        },
        explore: {
            title: "Your Journey Begins Now",
            description: "You have all the tools you need to start achieving your goals!",
            sections: [
                { 
                    name: "Remember", 
                    description: "Small, consistent habits lead to big achievements over time."
                },
                { 
                    name: "Tip", 
                    description: "Review your progress weekly and adjust your habits as needed."
                },
                { 
                    name: "Success", 
                    description: "Focus on building streaks - consistency is more important than perfection."
                }
            ]
        }
    }
};
