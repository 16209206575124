import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Navbar from "../components/page/Navbar";
import SideBar from "../components/page/SideBar";
import Footer from "../components/page/Footer";
import { AuthContext } from "../context/AuthContext";
import { api } from "../api/api.js";

const Script = () => {
    const { user } = useContext(AuthContext);

    const handleRunScript = async () => {
        try {
            const res = await api.post(`/api/goal/script`);

            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            console.log('Script run successfully:');
            // Optionally show a success message to the user
        } catch (error) {
            console.error('Error running script:', error);
            // Optionally show an error message to the user
        }
    };

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Welcome to AImbition</title>
                <meta
                    name="description"
                    content="Achieve your goals with AImbition. Explore personalized insights and actionable tips tailored to your focus areas and challenges."
                />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'} border-end`}>
                        <SideBar />
                    </div>
                </div>
                <div className="col flex-grow-1">
                    <div className="row py-3">
                        <div className="col-auto my-auto">
                            <div href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="btn border border-dark rounded-3 p-2">
                                Side Menu
                            </div>
                        </div>
                    </div>
                    {user ? (
                        <div>
                            <Button onClick={handleRunScript} variant="primary">
                                Run Script
                            </Button>
                        </div>
                    ) : (
                        <p>Please log in to run scripts.</p>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col bg-white border-top border-dark">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Script;