import '../src/styles/styles.css'; // The path should be relative to the current file

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthContext } from './context/AuthContext';
import { TutorialProvider } from './contexts/TutorialContext';
import TutorialBanner from './components/tutorial/TutorialBanner';

// import Account from "./pages/Account";
import Activate from "./pages/Activate";
import AIPlan from "./pages/AIPlan";
import Cancel from "./pages/Cancel";
import Home from "./pages/Home";
import Focus from "./pages/FocusGoals";
import Goals from "./pages/Goals";
import Habits from "./pages/Habits";
import Instructions from "./pages/Instructions";
import List from "./pages/List";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Plans from "./pages/Plans";
import Register from "./pages/Register";
import ResetEmail from "./pages/ResetEmail";
import ResetPassword from "./pages/ResetPassword";
import Profile from "./pages/Profile";
import Script from "./pages/Script";
import Success from "./pages/Success";
import Workspace from "./pages/Workspace";
// import HabitStatusWidget from './components/workspace/HabitStatusWidget';

function App() {
    const { user } = useContext(AuthContext);


    useEffect(() => {
        const LAST_VISITED_KEY = 'lastVisited';
        const lastVisited = new Date(localStorage.getItem(LAST_VISITED_KEY));
        const today = new Date();

        // Check if the app is accessed for the first time or the last access date was before today
        if (!lastVisited || lastVisited.toDateString() !== today.toDateString()) {
            localStorage.setItem(LAST_VISITED_KEY, today.toString());
            window.location.reload(); // Refresh the page
        }
    }, []);

    // useEffect(() => {
    //   const handleVisibilityChange = () => {
    //     if (!document.hidden) {
    //       window.location.reload();
    //     }
    //   };
    //   document.addEventListener("visibilitychange", handleVisibilityChange, false);
    //   return () => {
    //     document.removeEventListener("visibilitychange", handleVisibilityChange, false);
    //   };
    // }, []);

    return (
        <GoogleOAuthProvider clientId="411046985952-6id3uvrs3jb2fdc6gmh02icaaqubbsdc.apps.googleusercontent.com">
            <TutorialProvider>
                <BrowserRouter>
                <TutorialBanner />
                <Routes>
                    <Route path="/activate/:hash" element={<Activate />} />
                    {/* <Route path="/ai" element={<AIPlan />} /> */}
                    <Route path="/cancel" element={<Cancel />} />
                    <Route path="/focus" element={<Focus />} />
                    <Route path="/goals" element={<Goals />} />
                    <Route path="/habits" element={<Habits />} />
                    <Route path="/home" element={<Home user={user} focusAreas={user?.focusAreas || []} challenges={user?.challenges || []} />} />
                    <Route path="/instructions" element={<Instructions />} />
                    <Route path="/list" element={<List />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="/plans" element={<Plans />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/reset-email" element={<ResetEmail />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/script" element={<Script />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/workspace" element={<Workspace />} />
                    <Route path="/" element={<Home user={user} focusAreas={user?.focusAreas || []} challenges={user?.challenges || []} />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <ToastContainer />
                </BrowserRouter>
            </TutorialProvider>
        </GoogleOAuthProvider>
    );
}

export default App;
