import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPlus } from '@fortawesome/free-solid-svg-icons';

const AIHabits = ({ user, category, aiHabits, loading, handleAddHabit }) => {
    const [visibleCount, setVisibleCount] = useState(5); // Start with 5 visible habits

    const handleShowMoreHabits = () => {
        setVisibleCount(prevCount => prevCount + 5); // Increase visible count by 5
    };

    return (
        <>
            {user ? (
                <section className="mb-4">
                    <div className="block block-primary">
                        <div className="block-header">
                            <h4 className="mb-0 fw-bold">
                                ✨ AI-Suggested {category} Habits
                            </h4>
                        </div>
                        <div className="block-body">
                            <p className="mb-3">Smart habit recommendations to help you achieve your goals</p>
                            {loading ? (
                                <div className="text-center p-4">
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" className="text-secondary mb-3" />
                                    <p className="mb-0">Generating personalized habit suggestions...</p>
                                </div>
                            ) : (
                                <>
                                    {aiHabits && aiHabits.length > 0 ? (
                                        <div className="row g-3">
                                            {(aiHabits || []).slice(0, visibleCount).map((habit, index) => (
                                                <div key={index} className="col-md-6 col-sm-6 col-12">
                                                    <div 
                                                        className="inner-block inner-block--suggestion"
                                                        onClick={() => handleAddHabit(habit)}
                                                    >
                                                        <div className="inner-block__content p-2">
                                                            <div className="inner-block__text">
                                                                {habit}
                                                            </div>
                                                        </div>
                                                        <div className="inner-block__actions p-2">
                                                            <button 
                                                                className="button button--sm button--icon-only button--outline-primary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleAddHabit(habit);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faPlus} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                ) : (
                                    <p className="text-center text-muted mb-0">Select a goal to see AI habit suggestions</p>
                                )}
                                {aiHabits && aiHabits.length > visibleCount && (
                                    <div className="text-center mt-3">
                                        <button onClick={handleShowMoreHabits} className="button button--sm button--outline-primary">
                                            Show More Suggestions
                                        </button>
                                    </div>
                                )}
                            </>
                        )}

                        </div>
                    </div>
                </section>
            ) : (
                <></>
            )}
        </>
    );
};

export default AIHabits;
