import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';

const TutorialContext = createContext();

export const TutorialProvider = ({ children }) => {
    const { user } = useContext(AuthContext);
    const userId = user?._id;
    
    // Check localStorage on initialization to see if tutorial was completed for this specific user
    const getTutorialCompletionStatus = useCallback(() => {
        if (!userId) return false;
        
        const userTutorialKey = `hasCompletedTutorial_${userId}`;
        return localStorage.getItem(userTutorialKey) === 'true';
    }, [userId]);
    
    const hasCompletedTutorial = getTutorialCompletionStatus();
    
    const [tutorialState, setTutorialState] = useState({
        currentStep: hasCompletedTutorial ? 'complete' : 'welcome',
        currentSubStep: 'command',
        showExplorationBanner: !hasCompletedTutorial && !!userId,
        showGoalsBanner: false,
        showHabitsBanner: false,
        showProfileBanner: false,
        completed: hasCompletedTutorial
    });
    
    // Update tutorial state when user changes
    useEffect(() => {
        if (userId) {
            const userCompletedTutorial = getTutorialCompletionStatus();
            setTutorialState(prev => ({
                ...prev,
                currentStep: userCompletedTutorial ? 'complete' : 'welcome',
                showExplorationBanner: !userCompletedTutorial,
                completed: userCompletedTutorial
            }));
        } else {
            // If no user is logged in, hide the tutorial
            setTutorialState(prev => ({
                ...prev,
                showExplorationBanner: false
            }));
        }
    }, [userId, getTutorialCompletionStatus]);

    const setTutorialStep = (step) => {
        setTutorialState(prev => ({
            ...prev,
            currentStep: step,
            currentSubStep: 'command'
        }));
    };

    const setSubStep = (subStep) => {
        setTutorialState(prev => ({
            ...prev,
            currentSubStep: subStep
        }));
    };

    const toggleExplorationBanner = (show) => {
        setTutorialState(prev => ({
            ...prev,
            showExplorationBanner: show
        }));
    };

    const completeTutorial = () => {
        if (!userId) return; // Only complete tutorial if user is logged in
        
        setTutorialState(prev => ({
            ...prev,
            currentStep: 'complete',
            completed: true,
            showExplorationBanner: false
        }));
        // Save to localStorage with user-specific key
        const userTutorialKey = `hasCompletedTutorial_${userId}`;
        localStorage.setItem(userTutorialKey, 'true');
    };

    const skipTutorial = () => {
        completeTutorial();
    };
    
    // Add a function to reset the tutorial if needed
    const resetTutorial = () => {
        setTutorialState({
            currentStep: 'welcome',
            currentSubStep: 'command',
            showExplorationBanner: true,
            showGoalsBanner: false,
            showHabitsBanner: false,
            showProfileBanner: false,
            completed: false
        });
        localStorage.removeItem('hasCompletedTutorial');
    };

    return (
        <TutorialContext.Provider 
            value={{
                tutorialState,
                setTutorialStep,
                setSubStep,
                toggleExplorationBanner,
                completeTutorial,
                skipTutorial,
                resetTutorial
            }}
        >
            {children}
        </TutorialContext.Provider>
    );
};

export const useTutorial = () => {
    const context = useContext(TutorialContext);
    if (!context) {
        throw new Error('useTutorial must be used within a TutorialProvider');
    }
    return context;
};
