import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import '../../styles/styles.css';

const SideBar = () => {
    const { user } = useContext(AuthContext);

    return (
        <div className="sidebar">
            <div id="sidebar-nav" className="list-group">
                <NavLink to="/" className="list-group-item" end>
                    🛠️
                    Work
                </NavLink>
                {/* {user && (
                    <NavLink to="/ai" className="list-group-item border-start-0 border-end-0" style={({ isActive }) => isActive ? activeLinkStyles : linkStyles}>
                        <FontAwesomeIcon icon={faLightbulb} className="mr-2" />
                        &nbsp;Plan AI
                    </NavLink>
                )} */}
                <NavLink to="/goals" className="list-group-item">
                    🎯
                    Goals
                </NavLink>
                <NavLink to="/habits" className="list-group-item">
                    🌱
                    Habits
                </NavLink>
                {/* {user && (
                    <NavLink to="/workspace" className="list-group-item border-start-0 border-end-0" style={({ isActive }) => isActive ? activeLinkStyles : linkStyles}>
                        <FontAwesomeIcon icon={faTachometerAlt} className="mr-2" />
                        &nbsp;Dashboard
                    </NavLink>
                )} */}
                {user && (
                    <NavLink to="/profile" className="list-group-item">
                        👤
                        Profile
                    </NavLink>
                )}
                {/* {user && (
                    <NavLink to="/plans" className="list-group-item border-start-0 border-end-0" style={({ isActive }) => isActive ? activeLinkStyles : linkStyles}>
                        <FontAwesomeIcon icon={faArrowAltCircleUp} className="mr-2" />
                        &nbsp;Upgrade
                    </NavLink>
                )} */}
            </div>
        </div>
    );
};

export default SideBar;