import { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Footer from "../components/page/Footer";
import { api } from "../api/api.js";
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../context/AuthContext';

const Register = () => {
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const [step, setStep] = useState(1);
    const [info, setInfo] = useState({
        focusAreas: [], // Changed from focusArea to focusAreas as an array to match backend model
        challenges: [],
        username: "",
        email: "",
        password: "",
        goalsLevel: "free"
    });
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [message, setMessage] = useState(undefined);

    const focusAreas = [
        "Career", "Self-Development", "Family", "Finance", "Fun", "Health", "Relationship", "Spiritual"
    ];

    const challengeAreas = [
        'No Clarity', 'No Strategy', 'Procrastinate', 'Inconsistent',
        'Fear of Failure', 'Overwhelmed', 'No Resources', 'Distracted', 'Negative', 'No Time', 'No Support', 'Unrealistic'
    ];

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setInfo(prevInfo => ({ ...prevInfo, [id]: value }));
    };

    const handleFocusAreaChange = (area) => {
        // Update to handle focusAreas as an array with a single selection
        setInfo(prevInfo => ({
            ...prevInfo,
            focusAreas: [area] // Store as an array with a single value to match backend model
        }));
    };

    const handleChallengeChange = (challenge) => {
        setInfo(prevInfo => ({
            ...prevInfo,
            challenges: prevInfo.challenges.includes(challenge)
                ? prevInfo.challenges.filter(c => c !== challenge)
                : [...prevInfo.challenges, challenge]
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post("/api/auth/register", info);
            if (response.status === 200) {
                setMessage("Registration success - Check your email for activation link.");
                setErrorMessage(undefined);
            } else {
                setErrorMessage(response.data.message || "An unknown error occurred");
                setMessage(undefined);
            }
        } catch (error) {
            setErrorMessage(error.response?.data?.message || "Could not register");
            setMessage(undefined);
        }
    };

    const nextStep = () => {
        if (step === 1 && (!info.focusAreas || info.focusAreas.length === 0)) {
            setErrorMessage("Select an area you want to focus on");
            return;
        }
        if (step === 2 && info.challenges.length === 0) {
            setErrorMessage("Please select at least one challenge");
            return;
        }
        setStep(prev => prev + 1);
        setErrorMessage(undefined);
    };

    const prevStep = () => {
        setStep(prev => prev - 1);
        setErrorMessage(undefined);
    };

    const renderStep1 = () => (
        <div className="card-body px-4">
            <h5 className="card-title mb-4">Select an area you want to focus on</h5>
            <div className="row g-3">
                {focusAreas.map((area) => {
                    const isSelected = info.focusAreas.includes(area);
                    return (
                        <div key={area} className="col-6 col-md-4">
                            <button
                                type="button"
                                style={{ fontSize: '.8rem' }}
                                className={`btn w-100 ${isSelected ? 'btn-primary-custom selected' : 'btn-primary-custom-outline'}`}
                                onClick={() => handleFocusAreaChange(area)}
                            >
                                {area}
                            </button>
                        </div>
                    );
                })}
            </div>
            <div className="d-flex justify-content-end mt-4">
                <button
                    type="button"
                    className="btn btn-primary-custom"
                    onClick={nextStep}
                >
                    Next
                </button>
            </div>
        </div>
    );

    const renderStep2 = () => (
        <div className="card-body px-4">
            <h5 className="card-title mb-4">Select your largest challenges</h5>
            <div className="row g-3">
                {challengeAreas.map((challenge) => {
                    const isSelected = info.challenges.includes(challenge);
                    return (
                        <div key={challenge} className="col-6 col-md-4">
                            <button
                                type="button"
                                style={{ fontSize: '.9rem' }}
                                className={`btn w-100 h-100 ${isSelected ? 'btn-primary-custom selected' : 'btn-primary-custom-outline'}`}
                                onClick={() => handleChallengeChange(challenge)}
                            >
                                {challenge}
                            </button>
                        </div>
                    );
                })}
            </div>
            <div className="d-flex justify-content-between mt-4">
                <button className="btn btn-secondary" onClick={prevStep}>
                    Back
                </button>
                <button className="btn btn-primary-custom" onClick={nextStep}>
                    Next
                </button>
            </div>
        </div>
    );

    const renderStep3 = () => (
        <div className="card-body px-4">
            <h5 className="card-title mb-4">Choose Your Plan</h5>
            <div className="row g-4">
                <div className="col-md-6">
                    <div className={`card h-100 ${info.goalsLevel === 'free' ? 'border-primary' : ''}`}>
                        <div className="card-header bg-success text-white text-center">
                            <span className="badge bg-white text-success">AVAILABLE NOW</span>
                        </div>
                        <div className="card-body justify-content-between">
                            <div>
                                <h5 className="card-title">Free Plan</h5>
                                <ul className="list-unstyled">
                                    <li>✓ Basic goal tracking</li>
                                    <li>✓ Limited AI assistance</li>
                                    <li>✓ Basic analytics</li>
                                </ul>
                            </div>
                            <button
                                className={`btn ${info.goalsLevel === 'free' ? 'btn-success' : 'btn-outline-success'}`}
                                onClick={() => setInfo(prev => ({ ...prev, goalsLevel: 'free' }))}
                            >
                                Select Free Plan
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className={`card h-100 opacity-75`}>
                        <div className="card-header bg-primary text-white text-center">
                            <span className="badge bg-white text-primary">COMING SOON</span>
                        </div>
                        <div className="card-body justify-content-between">
                            <div>
                                <h5 className="card-title">Premium Plan</h5>
                                <ul className="list-unstyled">
                                    <li>✓ Unlimited goal tracking</li>
                                    <li>✓ Advanced AI assistance</li>
                                    <li>✓ Detailed analytics</li>
                                    <li>✓ Priority support</li>
                                    <li>✓ Smart habit streaks</li>
                                    <li>✓ Goal templates & sharing</li>
                                </ul>
                            </div>
                            <button
                                className="btn btn-outline-primary"
                                disabled
                            >
                                Coming Soon
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
                <button className="btn btn-secondary" onClick={prevStep}>
                    Back
                </button>
                <button className="btn btn-primary-custom" onClick={nextStep}>
                    Next
                </button>
            </div>
        </div>
    );
    const renderStep4 = () => {
        return (
        <div className="card-body px-4">
            <h5 className="card-title mb-4">Complete Your Registration</h5>
            <div className="row">
                <div className="col-md-6">
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                    }}>
                        <h6 className="mb-3">Manual Registration</h6>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">Username</label>
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                name="username"
                                value={info.username}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={info.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                value={info.password}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            <button
                                className="btn btn-secondary"
                                onClick={prevStep}
                                type="button"
                            >
                                Back
                            </button>
                            <button
                                className="btn btn-primary-custom"
                                type="submit"
                            >
                                Register
                            </button>
                        </div>
                    </form>
                </div>
                
                <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                    <h6 className="mb-3">Or use Google</h6>
                    <div className="d-flex justify-content-center">
                        <GoogleLogin
                            className="mx-auto"
                            buttonText="Register with Google"
                            onSuccess={handleGoogleAuthResponse}
                            onFailure={handleGoogleFailure}
                        />
                    </div>
                    <small className="text-muted mt-2">Sign up instantly with your Google account</small>
                </div>
            </div>
        </div>
        );
    };

    // Google Auth handlers

    const handleGoogleAuthResponse = async (response) => {
        console.log("in handleGoogleAuthResponse");
        const tokenId = response.credential;

        try {
            // Verify Google token with backend
            const res = await api.post('/api/auth/validate-google', { token: tokenId });
            if (res.status === 200) {
                console.log("Google verification successful. User details:", res.data);

                // Populate info state with Google details
                setInfo(prev => ({
                    ...prev,
                    email: res.data.email || "", // Ensure email is a string
                    username: res.data.name || "", // Check if email is defined
                    password: "", // Password not required for Google users
                }));

                // Move to the next step if applicable
                setStep(4); // Ensure they're on the final registration step
            }
        } catch (err) {
            console.error("Error during Google registration:", err);
            setErrorMessage(err.response?.data?.message || "Google registration failed");
        }
    };

    const handleGoogleFailure = (error) => {
        console.log("in handleGoogleFailure");
        console.error("Google Sign In was unsuccessful. Try Again Later.", error);
    };

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Register - AImbition</title>
                <meta name="description" content="Join AImbition to start your journey towards a more fulfilling life. Set empowering goals and cultivate productive habits." />
            </Helmet>
            <div className="row g-0 bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar className="navbar-class" />
                </div>
            </div>
            <div className="row justify-content-center flex-no-wrap">
                <div className="col-md-6 col-sm-10 p-4">
                    <div className="card shadow-sm rounded-4">
                        <div className="card-header bg-white py-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="mb-0 fw-bold">Register</h4>
                                <div className="text-muted">Step {step} of 4</div>
                            </div>
                            <div className="progress mt-3" style={{ height: "4px" }}>
                                <div
                                    className="progress-bar bg-primary"
                                    role="progressbar"
                                    style={{ width: `${(step / 4) * 100}%` }}
                                />
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="alert alert-danger m-3">
                                {errorMessage}
                            </div>
                        )}
                        {message && (
                            <div className="alert alert-success m-3">
                                {message}
                            </div>
                        )}
                        <div className="card-body p-4">
                            {step === 1 && renderStep1()}
                            {step === 2 && renderStep2()}
                            {step === 3 && renderStep3()}
                            {step === 4 && renderStep4()}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row fixed-bottom">
                <div className="col bg-white border-top border-dark">
                    <Footer className="footer-class" />
                </div>
            </div>
        </div>
    );
};

export default Register;
