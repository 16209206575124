import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import SearchItem from '../page/SearchItem';

const PopularGoals = ({ category, allGoals, loading, user, checkIfLinked, handleAddGoal, handleShowConfirmModal }) => {
    // Console log to debug loading state and allGoals
    console.log("Loading state:", loading);
    console.log("All Goals:", allGoals);

    return (
        <section className="mb-4">
            <div className="block block-primary">
                <div className="block-header">
                    <h4 className="mb-0 fw-bold">
                        🎯 Popular {category} Goals
                    </h4>
                </div>
                <div className="block-body">
                    {loading || allGoals.length === 0 ? (
                        <div className="text-center p-4">
                            <FontAwesomeIcon icon={faSpinner} spin size="2x" className="text-secondary mb-3" />
                            <p className="mb-0">Loading goals...</p>
                        </div>
                    ) : (
                        <div className="row g-3">
                            {allGoals.map((item) => (
                                <SearchItem
                                    key={item._id}
                                    item={item}
                                    linked={checkIfLinked(item._id)}
                                    user={user}
                                    entity="goal"
                                    handleAdd={() => handleShowConfirmModal(item.headline)}
                                    toastMessage="Goal added successfully!"
                                    toastIcon="check"
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default PopularGoals;
