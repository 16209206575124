import { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Footer from "../components/page/Footer";
import { api } from "../api/api.js";

const Activate = () => {
    const { hash } = useParams();
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");

    const handleActivation = async () => {
        try {
            const res = await api.post(`/api/auth/activate`, { hash });
            // Accept both 200 and 201 status codes as success
            if (res.status === 200 || res.status === 201) {
                setMessage("Your account has been successfully activated");
                setErrorMessage("");
                console.log("Activation successful with status:", res.status);
            }
        } catch (error) {
            console.error("Activation error:", error);
            if (!error.response) {
                setErrorMessage("No server response. Please try again later.");
            } else if (error.response.status === 404) {
                setErrorMessage("User not found. The activation link may be invalid or expired.");
            } else {
                setErrorMessage(`Activation failed: ${error.response?.data?.message || "Unknown error"}`);
            }
        }
    };

    const renderMessage = () => {
        if (message) {
            return (
                <div className="card-body p-4 text-center">
                    <div className="alert alert-success mb-4">
                        {message}
                    </div>
                    <p className="mb-4">
                        Your account has been successfully activated. You can now access all features of AImbition.
                    </p>
                    <a href="/login" className="btn btn-primary-cust    om">
                        Go to Login
                    </a>
                </div>
            );
        }
        return (
            <div className="card-body p-4 text-center">
                <p className="mb-4">
                    Thank you for registering with AImbition. To complete your account setup and access all features, please activate your account by clicking the button below.
                </p>
                <button
                    onClick={handleActivation}
                    className="btn btn-primary-custom w-50"
                >
                    Activate Account
                </button>
            </div>
        );
    };

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Activate Account - AImbition</title>
                <meta name="description" content="Activate your AImbition account to start your journey towards a more fulfilling life." />
            </Helmet>
            <div className="row g-0 bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar className="navbar-class" />
                </div>
            </div>
            <div className="row justify-content-center flex-no-wrap">
                <div className="col-md-6 col-sm-10 p-4">
                    <div className="card shadow-sm rounded-4">
                        <div className="card-header bg-white py-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="mb-0 fw-bold">Account Activation</h4>
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="alert alert-danger m-3">
                                {errorMessage}
                            </div>
                        )}
                        {renderMessage()}
                    </div>
                </div>
            </div>
            <div className="row fixed-bottom">
                <div className="col bg-white border-top border-dark">
                    <Footer className="footer-class" />
                </div>
            </div>
        </div>
    );
};

export default Activate;