import React, { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HabitItem from './HabitItem';
import PropTypes from 'prop-types';

const HomeHabits = ({ completeHabits, incompleteHabits, workDay, updateHabitOrder, toggleHabitComplete }) => {
    const onDragEnd = useCallback((result) => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index) return;

        const reorderedHabits = Array.from(incompleteHabits);
        const [reorderedHabit] = reorderedHabits.splice(source.index, 1);
        reorderedHabits.splice(destination.index, 0, reorderedHabit);
        updateHabitOrder(reorderedHabits);
    }, [incompleteHabits, updateHabitOrder]);

    // This ensures the drag and drop context is properly isolated
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: 0,
        margin: '0 0 8px 0',
        width: draggableStyle.width, // Preserve original width during dragging

        // change background colour if dragging
        background: isDragging ? 'white' : 'transparent',
        boxShadow: isDragging ? '0 4px 8px rgba(0,0,0,0.1)' : 'none',

        // styles we need to apply on draggables
        ...draggableStyle
    });

    return (
        <div className="col-12 mb-1">
            <h5 className="mb-2">Active Habits</h5>
            {incompleteHabits && incompleteHabits.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="active-habits">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {incompleteHabits.map((habit, index) => (
                                    <Draggable key={habit._id} draggableId={habit._id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <HabitItem
                                                    workDay={workDay}
                                                    user2habit={habit}
                                                    toggleHabitComplete={toggleHabitComplete}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <p>---</p>
            )}

            <h5 className="mt-4 mb-2">Completed Habits</h5>
            {completeHabits && completeHabits.length > 0 ? (
                <div>
                    {completeHabits.map((habit) => (
                        <div key={habit._id} className="mb-2">
                            <HabitItem
                                workDay={workDay}
                                user2habit={habit}
                                toggleHabitComplete={toggleHabitComplete}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <p>---</p>
            )}
        </div>
    );
};

HomeHabits.propTypes = {
    completeHabits: PropTypes.array.isRequired,
    incompleteHabits: PropTypes.array.isRequired,
    workDay: PropTypes.string.isRequired,
    updateHabitOrder: PropTypes.func.isRequired,
    toggleHabitComplete: PropTypes.func.isRequired,
};

export default HomeHabits;
