import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Navbar from "../components/page/Navbar";
import Footer from "../components/page/Footer";
import SideBar from "../components/page/SideBar";
import { AuthContext } from "../context/AuthContext";
import { api } from "../api/api.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faSpinner, faCheck, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Button, Collapse, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

const FocusGoals = () => {
    const { user } = useContext(AuthContext);
    const focusAreas = user?.focusAreas || [];
    const [showModal, setShowModal] = useState(false);
    const [selectedFocusArea, setSelectedFocusArea] = useState(null);

    // State variables
    const [focusRecommendations, setFocusRecommendations] = useState([]);
    const [recommendationsLoading, setRecommendationsLoading] = useState(false);
    const [visibleCount, setVisibleCount] = useState(5);
    const [visibleGoalsCount, setVisibleGoalsCount] = useState({});
    const [openSections, setOpenSections] = useState({ focusAreas: true, challenges: true });

    // Toggle section visibility
    const toggleSection = (section) => {
        setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
    };

    const handleShowMore = () => setVisibleCount(prevCount => prevCount + 5);

    const handleShowMoreGoals = (focusArea) => {
        setVisibleGoalsCount(prev => ({ ...prev, [focusArea]: (prev[focusArea] || 5) + 5 }));
    };

    // Toast notification
    const showToast = (message) => {
        toast(<CustomToastContent message={message} />, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progressClassName: 'custom-toast-progress',
            className: 'custom-toast',
        });
    };

    const CustomToastContent = ({ message }) => (
        <div className="custom-toast-content">
            <FontAwesomeIcon icon={faCheck} className="custom-toast-check" />
            <p>{message}</p>
        </div>
    );

    // Fetch focus areas information
    const getFocusAreasInfo = async (focusAreas) => {
        console.log("AIPlan: getFocusAreasInfo");
        setRecommendationsLoading(true);
        try {
            const res = await api.post(`/api/ai/runfocusareasprompt`, { focusAreas });
            const data = res.data.data;
            setFocusRecommendations(data);
        } catch (error) {
            console.error("Error fetching related goals and habits", error);
        } finally {
            setRecommendationsLoading(false);
        }
    };

    // useEffect hooks for data fetching
    useEffect(() => {
        if (user && user.focusAreas) {
            getFocusAreasInfo(user.focusAreas);
        }
    }, [user]);

    // Handle focus area selection
    const handleFocusAreaSelect = (focusArea) => {
        setSelectedFocusArea(focusArea);
        setShowModal(false);
        showToast(`Selected focus area: ${focusArea}`);
    };
    const handleAddGoal = async (goal, category) => {
        console.log("in handleAddGoal");
        console.log("goal", goal);
        console.log("category", category);

        try {
            const res = await api.post(`/api/goal/createuser`, {
                text: goal,
                description: goal,
                headline: goal,
                category: category,
                user: user._id,
            });
            showToast("Goal added successfully!");
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Focus Goals</title>
                <meta name="description" content="Focus on your goals and track your progress effectively." />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'}`}>
                        <SideBar />
                    </div>
                </div>
                <div className="col flex-grow-1">
                    <div className="row py-3">
                        <div className="col-auto my-auto">
                            <div href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="btn border border-dark rounded-3 p-2">
                                Side Menu
                            </div>
                        </div>
                    </div>
                    {user ? (
                        <>
                            <section className="mb-5">
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <h4 className="mb-0">Your Focus Areas</h4>
                                    <Button onClick={() => setShowModal(true)} className="btn btn-primary">Change Focus Area</Button>
                                </div>
                                <Collapse in={true}>
                                    <div>
                                        <div className="row g-4">
                                            {focusRecommendations.map(({ focusArea, relatedGoals }, index) => (
                                                <div key={index} className="col-md-4 col-sm-6 mb-3">
                                                    <div className="card h-100 shadow-sm">
                                                        <div className="card-body d-flex flex-column gap-2 p-2">
                                                            <div className="d-flex align-items-center justify-content-start mx-auto py-1">
                                                                <div className="me-2 align-self-center d-flex align-items-center">
                                                                    <FontAwesomeIcon icon={faBullseye} size="2x" className="text-secondary" />
                                                                </div>
                                                                <h4 className="card-title align-middle mb-0 d-flex align-items-center">{focusArea}</h4>
                                                            </div>
                                                            <div className="card bg-white">
                                                                <div className="card-body p-2">
                                                                    <h5 className="card-title mb-3">Popular Goals:</h5>
                                                                    <div className="d-flex flex-wrap gap-2">
                                                                        {(relatedGoals || []).slice(0, visibleGoalsCount[focusArea] || 5).map((goal, index) => (
                                                                            <Button key={index} type="button" className="btn btn-primary-custom" onClick={() => handleAddGoal(goal, focusArea)}>
                                                                                {goal}
                                                                            </Button>
                                                                        ))}
                                                                    </div>
                                                                    {(relatedGoals || []).length > (visibleGoalsCount[focusArea] || 5) && (
                                                                        <div className="text-end mt-3">
                                                                            <Button type="button" className="btn bg-secondary btn-sm" onClick={() => handleShowMoreGoals(focusArea)}>
                                                                                Show More..
                                                                            </Button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Collapse>
                            </section>
                        </>
                    ) : (
                        <div className="row d-flex justify-content-between">
                            {/* Render features or other content for non-logged in users */}
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col bg-white border-top border-dark">
                    <Footer />
                </div>
            </div>

            {/* Modal for selecting focus area */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Focus Area</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {focusAreas.map((focusArea, index) => (
                            <Button key={index} className="btn btn-outline-primary m-2" onClick={() => handleFocusAreaSelect(focusArea)}>
                                {focusArea}
                            </Button>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FocusGoals;