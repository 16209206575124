import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUndo } from '@fortawesome/free-solid-svg-icons';
import '../../styles/styles.css';

const HabitItem = ({ user2habit, workDay, toggleHabitComplete }) => {
    const [habitComplete, setHabitComplete] = useState(false);

    useEffect(() => {
        const isHabitComplete = user2habit?.completionHistory.some(history =>
            history.date === workDay && history.completed
        );

        setHabitComplete(isHabitComplete);
    }, [user2habit, workDay]);

    return (
        <div className={`inner-block inner-block--habit ${habitComplete ? 'inner-block--completed' : ''}`}>
            <div className="inner-block__content">
                <div className="inner-block__text">
                    {user2habit.habit.text}
                </div>
            </div>
            <div className="inner-block__actions">
                <button
                    onClick={() => toggleHabitComplete(user2habit._id, workDay, !habitComplete)}
                    className={`button button--sm ${habitComplete ? 'button--outline-success' : 'button--success'}`}
                >
                    <FontAwesomeIcon icon={habitComplete ? faUndo : faCheck} className="button__icon" />
                    {habitComplete ? 'Undo' : 'Complete'}
                </button>
            </div>
        </div>
    );
};

export default HabitItem;
