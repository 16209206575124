import { wwwApp } from "../../urls"
import { useContext } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import { api } from "../../api/api.js";

const Navbar = () => {
    const { user } = useContext(AuthContext);
    const { dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleClick = async (e) => {
        e.preventDefault();
        dispatch({ type: "LOGOUT" });
        try {
            // Await the completion of the POST request
            //await api.post(`/api/auth/logout`);
            // Navigate to the home page only after the POST request is completed
            navigate("/home");
        } catch (err) {
            console.error(err);
            // Handle any errors that occur during the POST request
        }
    };

    return (
        <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href={wwwApp} >Goal Setting Online</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
                aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav ms-auto my-2">
                    {user ?
                        <li className="nav-item"><NavLink className="nav-link" to="/logout" onClick={handleClick}>Logout</NavLink></li>
                        :
                        <li className="nav-item"><NavLink className="nav-link" to="/login" >Login</NavLink></li>
                    }
                </ul>
            </div>
        </nav >
    );
};

export default Navbar;