import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TaskItem from './TaskItem';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { getToday } from "../../utils/theDateHelper.js"; // Fix the import statement

const Loading = () => <div>Loading...</div>;
const Error = ({ message }) => <div>Error: {message}</div>;

const HomeTasks = ({ workDay, incompleteTasks, completeTasks, dateTasks, addTask, deleteTask, toggleTaskComplete, updateTaskOrder }) => {
    const [taskText, setTaskText] = useState("");
    const [tasksLoading, setTasksLoading] = useState(false);
    const [tasksError, setTasksError] = useState(null);

    useEffect(() => {
        const fetchTasks = async () => {
            setTasksLoading(true);
            setTasksError(null);
            try {
                // Fetch tasks logic here if needed
            } catch (error) {
                setTasksError('Error retrieving tasks');
            } finally {
                setTasksLoading(false);
            }
        };

        fetchTasks();
    }, []);

    if (tasksLoading) return <Loading />;
    if (tasksError) return <Error message={tasksError} />;

    const onDragEnd = async (dragResult) => {
        const { source, destination } = dragResult;
        if (!destination || source.index === destination.index) return;
        const reorderedTasks = Array.from(incompleteTasks);
        const [reorderedTask] = reorderedTasks.splice(source.index, 1);
        reorderedTasks.splice(destination.index, 0, reorderedTask);
        updateTaskOrder(reorderedTasks);
    };

    const handleAddTask = async (e) => {
        e.preventDefault();
        if (!taskText.trim()) return;
        await addTask(taskText);
        setTaskText("");
    };

    return (
        <div className="col-12 mb-1">
            <div className="row m-0 mb-2 p-0">
                <div className="col-12 d-flex justify-content-center align-items-center">
                    <form onSubmit={handleAddTask}>
                        <div className="input-group">
                            <input
                                className="form-control"
                                required
                                type="text"
                                placeholder="Add new task..."
                                value={taskText}
                                onChange={(e) => setTaskText(e.target.value)}
                            />
                            <button className="btn btn-primary-custom" type="submit">
                                <FontAwesomeIcon icon={faCirclePlus} />
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {workDay === getToday() && (
                <div>
                    <h5>Completed Tasks</h5>
                    {dateTasks.length > 0 ? (
                        <div>
                            {dateTasks.map((task) => (
                                <TaskItem
                                    key={task._id}
                                    task={task}
                                    toggleTaskComplete={toggleTaskComplete}
                                    deleteTask={deleteTask}
                                />
                            ))}
                        </div>
                    ) : (
                        <p>---</p>
                    )}
                    <br />
                    <h5>Active Tasks</h5>
                    {incompleteTasks.length > 0 ? (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="active-tasks">
                                {(provided) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {incompleteTasks.map((task, index) => (
                                            <Draggable key={task._id} draggableId={task._id} index={index}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <TaskItem
                                                            key={task._id}
                                                            task={task}
                                                            toggleTaskComplete={toggleTaskComplete}
                                                            deleteTask={deleteTask}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    ) : (
                        <p>No Active Tasks! Add some above</p>
                    )}

                </div>
            )}

            {workDay < getToday() && (
                <div>
                    <h5>Completed Tasks</h5>
                    {dateTasks.length > 0 ? (
                        <div>
                            {dateTasks.map((task) => (
                                <TaskItem
                                    key={task._id}
                                    task={task}
                                    toggleTaskComplete={toggleTaskComplete}
                                    deleteTask={deleteTask}
                                />
                            ))}
                        </div>
                    ) : (
                        <p>---</p>
                    )}
                </div>
            )}
        </div>
    );
};

HomeTasks.propTypes = {
    workDay: PropTypes.string.isRequired,
    incompleteTasks: PropTypes.array.isRequired,
    completeTasks: PropTypes.array.isRequired,
    dateTasks: PropTypes.array.isRequired,
    addTask: PropTypes.func.isRequired,
    deleteTask: PropTypes.func.isRequired,
    toggleTaskComplete: PropTypes.func.isRequired,
    updateTaskOrder: PropTypes.func.isRequired,
};

export default HomeTasks;
