import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPlus } from '@fortawesome/free-solid-svg-icons';

const AIGoals = ({ user, category, aiGoals, loading, handleShowConfirmModal }) => {
    const [visibleCount, setVisibleCount] = useState(5); // Start with 5 visible goals

    const handleShowMoreGoals = () => {
        setVisibleCount(prevCount => prevCount + 5); // Increase visible count by 5
    };

    return (
        <>
            {user ? (
                <section className="mb-4">
                    <div className="block block-primary">
                        <div className="block-header">
                            <h4 className="mb-0 fw-bold">
                                ✨ AI-Suggested {category} Goals
                            </h4>
                        </div>
                        <div className="block-body">
                            <p className="text-muted mb-3">Smart recommendations based on your selected category and preferences</p>
                            {loading ? (
                                <div className="text-center p-4">
                                    <FontAwesomeIcon icon={faSpinner} spin size="2x" className="text-secondary mb-3" />
                                    <p className="mb-0">Generating personalized goal suggestions...</p>
                                </div>
                            ) : (
                                <>
                                    {aiGoals && aiGoals.length > 0 ? (
                                        <div className="row g-3">
                                            {(aiGoals || []).slice(0, visibleCount).map((goal, index) => (
                                                <div key={index} className="col-md-6 col-sm-6 col-12">
                                                    <div 
                                                        className="inner-block inner-block--suggestion"
                                                        onClick={() => handleShowConfirmModal(goal)}
                                                    >
                                                        <div className="inner-block__content">
                                                            <div className="inner-block__text">
                                                                {goal}
                                                            </div>
                                                        </div>
                                                        <div className="inner-block__actions">
                                                            <button 
                                                                className="button button--sm button--icon-only button--outline-primary"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleShowConfirmModal(goal);
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faPlus} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="text-center text-muted mb-0">Select a category to see AI suggestions</p>
                                    )}
                                    {aiGoals && aiGoals.length > visibleCount && (
                                        <div className="text-center mt-3">
                                            <button onClick={handleShowMoreGoals} className="button button--sm button--outline-primary">
                                                Show More Suggestions
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </section>
            ) : (
                <></>
            )}
        </>
    );
};

export default AIGoals;
