import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import SearchItem from '../page/SearchItem';

const PopularHabits = ({ category, allHabits = [], loading, user, checkIfLinked, handleAddHabit }) => {
    return (
        <section className="mb-4">
            <div className="block block-primary">
                <div className="block-header">
                    <h4 className="mb-0 fw-bold">
                        🌱 Popular {category} Habits
                    </h4>
                </div>
                <div className="block-body">
            {loading || allHabits.length === 0 ? (
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '150px' }}>
                    <FontAwesomeIcon icon={faSpinner} spin size="2x" className="text-secondary mb-3" />
                    <p className="mt-2">Loading habits...</p>
                </div>
            ) : (
                <div className="row g-3">
                    {allHabits.map((item) => (
                        <SearchItem
                            key={item._id}
                            item={item}
                            linked={checkIfLinked(item._id)}
                            user={user}
                            entity="habit"
                            handleAdd={() => handleAddHabit(item.headline, item._id)}
                            toastMessage="Habit added successfully!"
                        />
                    ))}
                </div>
            )}
                </div>
            </div>
        </section>
    );
};

export default PopularHabits;
