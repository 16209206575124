import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCheck, faUndo } from '@fortawesome/free-solid-svg-icons';
import '../../styles/styles.css';

const TaskItem = ({ task, toggleTaskComplete, deleteTask }) => {
    const handleComplete = () => {
        toggleTaskComplete(task._id, !task.complete);
    };
    
    return (
        <div className={`inner-block inner-block--task ${task.complete ? 'inner-block--completed' : ''}`}>
            <div className="inner-block__content">
                <div className="inner-block__text">
                    {task.text}
                </div>
            </div>
            <div className="inner-block__actions">
                <button
                    onClick={() => deleteTask(task._id)}
                    className="button button--sm button--icon-only button--outline-danger"
                    title="Delete task"
                >
                    <FontAwesomeIcon icon={faTrash} />
                </button>
                <button
                    onClick={handleComplete}
                    className={`button button--sm ${task.complete ? 'button--outline-success' : 'button--success'}`}
                >
                    <FontAwesomeIcon icon={task.complete ? faUndo : faCheck} className="button__icon" />
                    {task.complete ? 'Undo' : 'Complete'}
                </button>
            </div>
        </div>
    );
};

export default TaskItem;
