import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash, faArrowRight, faBullseye, faCheck, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../styles/styles.css';

const UserGoals = ({ category, userGoals, loading, updateGoalStatus, removeGoal, user }) => {
    const navigate = useNavigate();

    const navigateToHabits = (goal) => {
        navigate('/habits', {
            state: {
                fromGoal: true,
                goalText: goal.headline,
                goalCategory: goal.category
            }
        });
    };

    if (loading) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '150px' }}>
                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                <p className="mt-2">Loading goals...</p>
            </div>
        );
    }

    // Show all goals if category is "All", otherwise filter by category
    const filteredUserGoals = category === 'All'
        ? userGoals
        : userGoals.filter(userGoal => userGoal.goal.category === category);

    return (
        <>
            {user ? (
                <section className="mb-4">
                    <div className="block block-primary">
                        <div className="block-header">
                            <h4 className="mb-0 fw-bold">
                                🎯 Your {category === 'All' ? '' : category} Goals
                            </h4>
                        </div>
                        <div className="block-body">
                            {filteredUserGoals.length > 0 ? (
                                <div className="row g-3">
                                    {filteredUserGoals.map(({ goal, status, _id }) => (
                                        <div key={goal._id} className="col-md-6 col-sm-6 col-12">
                                            <div className="inner-block inner-block--goal h-100">
                                                <div className="inner-block__content">
                                                    <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                                                        <div>
                                                            <h5 className="fw-bold mb-1">{goal.headline}</h5>
                                                            <span className="badge bg-light text-secondary rounded-pill">{goal.category}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="inner-block__actions d-flex flex-column">
                                                    <div className="d-flex gap-2 mb-3">
                                                        <button
                                                            className="button button--sm button--outline-primary"
                                                            onClick={() => navigateToHabits(goal)}
                                                            title="View supporting habits"
                                                        >
                                                            <span className="d-inline-flex align-items-center">Habits <FontAwesomeIcon icon={faArrowRight} className="button__icon" /></span>
                                                        </button>
                                                        <button
                                                            className="button button--sm button--icon-only button--outline-danger"
                                                            onClick={() => removeGoal(_id)}
                                                            title="Delete goal"
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </div>
                                                    <div className="goal-status-buttons d-flex flex-wrap w-100 gap-2 mt-1">
                                                        <button
                                                            className={`button button--sm status-button ${status === 'Not Started' ? 'status-new' : 'status-outline'}`}
                                                            onClick={() => updateGoalStatus(_id, 'Not Started')}
                                                        >
                                                            New
                                                        </button>
                                                        <button
                                                            className={`button button--sm status-button ${status === 'Progressing' ? 'status-in-progress' : 'status-outline'}`}
                                                            onClick={() => updateGoalStatus(_id, 'Progressing')}
                                                        >
                                                            In Progress
                                                        </button>
                                                        <button
                                                            className={`button button--sm status-button ${status === 'Stuck' ? 'status-stuck' : 'status-outline'}`}
                                                            onClick={() => updateGoalStatus(_id, 'Stuck')}
                                                        >
                                                            Stuck
                                                        </button>
                                                        <button
                                                            className={`button button--sm status-button ${status === 'Complete' ? 'status-complete' : 'status-outline'}`}
                                                            onClick={() => updateGoalStatus(_id, 'Complete')}
                                                        >
                                                            Complete
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="empty-state p-4 text-center bg-light rounded-4">
                                    <p className="text-muted mb-0">No goals found{category !== 'All' ? ' in this category' : ''}. Try adding some!</p>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            ) : (
                <div className="feature-container">
                    <div className="card shadow-sm border-0 mb-4">
                        <div className="card-body p-3 p-md-4">
                            <h3 className="mb-2">Achieve More with Goal Setting</h3>
                            <p className="lead mb-3 d-none d-md-block">Setting clear, measurable goals is the first step toward personal and professional growth.</p>
                            <p className="mb-3 d-block d-md-none">Set clear goals for personal and professional growth.</p>

                            <div className="row g-2 mb-3 d-none d-md-flex">
                                <div className="col-md-4 mb-2">
                                    <div className="feature-item">
                                        <div className="feature-icon">
                                            <FontAwesomeIcon icon={faBullseye} size="lg" />
                                        </div>
                                        <div>
                                            <h5 className="mb-1">Define Your Direction</h5>
                                            <p className="text-muted mb-0">Clarify what matters most to you</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <div className="feature-item">
                                        <div className="feature-icon">
                                            <FontAwesomeIcon icon={faCheck} size="lg" />
                                        </div>
                                        <div>
                                            <h5 className="mb-1">Track Your Progress</h5>
                                            <p className="text-muted mb-0">Monitor achievements over time</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <div className="feature-item">
                                        <div className="feature-icon">
                                            <FontAwesomeIcon icon={faChevronUp} size="lg" />
                                        </div>
                                        <div>
                                            <h5 className="mb-1">Celebrate Success</h5>
                                            <p className="text-muted mb-0">Recognize your accomplishments</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-column flex-md-row justify-content-center gap-2 gap-md-3 mt-3">
                                <Link to="/register" className="btn btn-blue px-4 py-2">Get Started</Link>
                                <Link to="/login" className="btn btn-outline-blue px-4 py-2">Log In</Link>
                            </div>
                        </div>
                    </div>

                    <div className="d-md-block d-none">
                        <div className="card shadow-sm border-0">
                            <div className="card-header bg-light py-3">
                                <h4 className="mb-0">Popular Goal Categories</h4>
                            </div>
                            <div className="card-body p-4">
                                <div className="row g-3">
                                    {['Career', 'Health', 'Finance', 'Relationship', 'Self-Development', 'Family'].map(cat => (
                                        <div key={cat} className="col-md-4 col-sm-6">
                                            <div className="card h-100 border-0 shadow-sm">
                                                <div className="card-body">
                                                    <h5 className="card-title">{cat}</h5>
                                                    <p className="card-text text-muted">Set meaningful {cat.toLowerCase()} goals and track your progress.</p>
                                                    <Link to="/register" className="btn btn-outline-blue btn-sm">Explore {cat} Goals</Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-md-none d-block">
                        <div className="card shadow-sm border-0">
                            <div className="card-header bg-light py-2">
                                <h5 className="mb-0">Popular Goal Categories</h5>
                            </div>
                            <div className="card-body p-3">
                                <div className="row g-2">
                                    {['Career', 'Health', 'Finance'].map(cat => (
                                        <div key={cat} className="col-4 mb-2">
                                            <Link to="/register" className="btn btn-outline-blue btn-sm d-block">{cat}</Link>
                                        </div>
                                    ))}
                                    {['Relationship', 'Development', 'Family'].map(cat => (
                                        <div key={cat} className="col-4 mb-2">
                                            <Link to="/register" className="btn btn-outline-blue btn-sm d-block">{cat}</Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default UserGoals;
