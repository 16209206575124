const Footer = () => {
    return (
        <footer className="footer">
            <div>
                Copyright 2024 GoalSetting.online
            </div>
        </footer>
    );
};

export default Footer;
