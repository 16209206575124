import '../styles/styles.css';
import { useState, useContext, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Navbar from "../components/page/Navbar";
import Footer from "../components/page/Footer";
import SideBar from "../components/page/SideBar";
import { api } from "../api/api.js";
import { AuthContext } from "../context/AuthContext";
import { faCheck, faBars, faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { HABIT_CATEGORIES } from '../utils/goalOptions';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import UserHabits from '../components/page/UserHabits';
import CustomHabitForm from '../components/page/CustomHabitForm';
import AIHabits from '../components/page/AIHabits';
import PopularHabits from '../components/page/PopularHabits';

const Habits = () => {
    const location = useLocation();
    const fromGoal = location.state?.fromGoal;
    const fromTutorial = location.state?.fromTutorial;
    const goalText = location.state?.goalText;
    const goalCategory = location.state?.goalCategory;
    const navigate = useNavigate();

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 Tutorial Functions 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    // Tutorial modal state and component are used when coming from tutorial flow
    const [showTutorialModal, setShowTutorialModal] = useState(false);

    useEffect(() => {
        if (fromTutorial) {
            setShowTutorialModal(true);
        }
    }, [fromTutorial]);

    // Tutorial modal component rendered at the bottom of the page
    const TutorialModal = ({ show, onHide }) => (
        <Modal show={show} onHide={onHide} className="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Select Supporting Habits</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="tutorial-step">
                    <h5>Let's choose habits that support your goal!</h5>
                    <p>For your goal "{goalText}", you can:</p>
                    <ol>
                        <li>Use AI to get personalized habit suggestions based on your goal</li>
                        <li>Browse popular habits that others have used successfully</li>
                        <li>Create your own custom habits that fit your lifestyle</li>
                    </ol>
                    <div className="mt-3 p-3 bg-light rounded">
                        <p className="mb-2"><strong>💡 Quick Tip:</strong></p>
                        <ul className="mb-0">
                            <li>We recommend selecting 2-3 habits to start with</li>
                            <li>Choose habits that you can realistically maintain</li>
                            <li>You can always add more habits later as you progress</li>
                        </ul>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Skip Tutorial</Button>
                <Button variant="primary" onClick={() => {
                    onHide();
                    navigate('/home', {
                        state: {
                            tutorialComplete: true,
                            newGoal: goalText,
                            newHabits: userHabits.length
                        }
                    });
                }}>Complete Tutorial</Button>
            </Modal.Footer>
        </Modal>
    );

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 STATE VARIABLES 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const { user } = useContext(AuthContext);
    const [userHabits, setUserHabits] = useState([]);
    const [allHabits, setAllHabits] = useState([]);
    const [aiHabits, setAiHabits] = useState([]);

    const [allHabitsLoading, setAllHabitsLoading] = useState(false);
    const [aiHabitsLoading, setAiHabitsLoading] = useState(false);
    const [userHabitsLoading, setUserHabitsLoading] = useState(false);

    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [pendingHabit, setPendingHabit] = useState(null);
    const [category, setCategory] = useState("");
    const [customHabit, setCustomHabit] = useState("");
    const [error, setError] = useState("");
    const [visibleCount, setVisibleCount] = useState(5);

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 General Functions 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const showToast = (message) => {
        toast(<CustomToastContent message={message} />, {
            position: toast.POSITION.BOTTOM_RIGHT,
            progressClassName: 'custom-toast-progress',
        });
    };

    const CustomToastContent = ({ message }) => (
        <div className="custom-toast-content">
            <FontAwesomeIcon icon={faCheck} className="custom-toast-check" />
            <p>{message}</p>
        </div>
    );

    // Pagination function - commented out as not currently used
    // const handleShowMoreHabits = () => {
    //     setVisibleCount(prevCount => prevCount + 5);
    // };

    const checkIfLinked = (id) => {
        if (!Array.isArray(userHabits)) {
            console.warn("userHabits is not defined or is not an array.");
            return true;
        }
        return userHabits.indexOf(id) === -1;
    };

    const handleCategorySelect = (selectedCategory) => {
        setCategory(selectedCategory);
        setShowCategoryModal(false);
        setAiHabitsLoading(true);
        retrieveCategoryHabits(selectedCategory);
        if (user) {
            retrieveAIHabits(selectedCategory);
            retrieveUserHabits();
        }
    };

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 Modal Management Functions 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const handleCloseModal = () => {
        setShowCategoryModal(false);
        document.getElementById('sidebar').classList.remove('show');
    };

    const handleShowConfirmModal = (habit, selectedCategory) => {
        console.log('handleShowConfirmModal - habit:', habit, 'selectedCategory:', selectedCategory);
        setPendingHabit({ text: habit, category: selectedCategory });
        setShowConfirmModal(true);
    };

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 Habit Functions 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    const retrieveUserHabits = useCallback(async () => {
        setUserHabitsLoading(true);
        try {
            const userHabitsRes = await api.get(`/api/user/populatehabits/${user._id}`);
            setUserHabits(userHabitsRes.data || []);
        } catch (err) {
            setError(err.message);
            toast.error('Error fetching user habits: ' + err.message);
        } finally {
            setUserHabitsLoading(false);
        }
    }, [user]);

    const retrieveAIHabits = useCallback(async (habitCategory, text = null) => {
        setAiHabitsLoading(true);
        try {
            const res = await api.post('/api/ai/runhabitprompt', {
                category: habitCategory,
                goalText: text // Pass the goal text if available
            });
            setAiHabits(res.data.data.relatedHabits || []);
        } catch (err) {
            setError(err.message);
            toast.error('Error fetching AI habits: ' + err.message);
        } finally {
            setAiHabitsLoading(false);
        }
    }, []);

    const retrieveCategoryHabits = useCallback(async (category) => {
        setAllHabitsLoading(true);
        try {
            const endpoint = category === 'All' || category === 'all'
                ? '/api/habit'
                : `/api/habit/category/${category}`;

            const predefinedHabitsRes = await api.get(endpoint);
            setAllHabits(predefinedHabitsRes.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setAllHabitsLoading(false);
        }
    }, []);
    
    const fetchHabits = useCallback(async (category) => {
        await retrieveUserHabits();
        await retrieveAIHabits(category);
        await retrieveCategoryHabits(category);
    }, [retrieveUserHabits, retrieveAIHabits, retrieveCategoryHabits]);

    const removeHabit = async (habitId) => {
        try {
            await api.delete(`/api/user2habit/${habitId}`);
            setUserHabits(prevHabits => prevHabits.filter(habit => habit._id !== habitId));
            showToast("Habit removed successfully!");
        } catch (error) {
            console.error(error);
            showToast("Error removing habit");
        }
    };

    const handleAddHabit = async (habitText, id) => {
        try {
            if (!id) {
                await api.post(`/api/habit/createuser`, {
                    headline: habitText,
                    text: habitText,
                    description: habitText,
                    category: category,
                    user: user._id,
                });
            } else {
                await api.post(`/api/user/addhabit`, {
                    user: user._id,
                    habit: id
                });
            }
            showToast(`Habit created successfully!`);
        } catch (error) {
            showToast(`Error creating habit: ${error.message}`);
            setError(error.message);
        }
    };

    const handleAddHabit2 = async (habit, habitCategory) => {
        try {
            console.log('handleAddHabit2 - habit:', habit, 'habitCategory:', habitCategory);

            const category = habit.category || habitCategory;
            console.log('Final category:', category);

            // Ensure we never use 'All' as a category
            if (category === 'All') {
                console.error('Cannot use "All" as a category');
                showToast("Error: Invalid category selected");
                return;
            }

            const res = await api.post(`/api/habit/createuser`, {
                text: habit.text,
                description: habit.text,
                headline: habit.text,
                category: category,
                user: user._id,
            });
            showToast("Habit added successfully!");
            await retrieveUserHabits();
        } catch (err) {
            console.error(err);
            showToast("Error adding habit");
        }
    };

    const confirmAdd = async (custom, id) => {
        try {
            if (custom) {
                await api.post(`/api/habit/createuser`, {
                    headline: customHabit,
                    text: customHabit,
                    description: customHabit,
                    category: category,
                    user: user._id,
                });
            } else {
                await api.post(`/api/user/addhabit`, {
                    user: user._id,
                    habit: id
                });
            }

            await Promise.all([
                retrieveCategoryHabits(category),
                retrieveAIHabits(category),
                retrieveUserHabits()
            ]);
        } catch (error) {
            console.error("Error adding habit:", error);
            showToast(`Error adding habit: ${error.message}`);
            setError(error.message);
        }
    };

    // Direct habit addition function - using handleShowConfirmModal instead
    // const startAdd = (habitText, id) => {
    //     setPendingHabit(habitText);
    //     setShowConfirmModal(true);
    // };

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 Effect Hooks 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢

    useEffect(() => {
        if (fromGoal && goalCategory) {
            setCategory(goalCategory);
            retrieveCategoryHabits(goalCategory);
            if (goalText) {
                retrieveAIHabits(goalCategory, goalText);
            }
        } else if (user && user.focusAreas && user.focusAreas.length > 0) {
            const selectedCategory = user.focusAreas[0];
            setCategory(selectedCategory);
            fetchHabits(selectedCategory);
        }
    }, [fromGoal, goalCategory, goalText, user, fetchHabits, retrieveCategoryHabits, retrieveAIHabits]);

    useEffect(() => {
        if (user && user.focusAreas && user.focusAreas.length > 0) {
            const selectedCategory = user.focusAreas[0];
            setCategory(selectedCategory);
            fetchHabits(selectedCategory);
        } else {
            // Fetch category habits even if no user is present
            setCategory('All');
            retrieveCategoryHabits('All');
        }
    }, [user, fetchHabits, retrieveCategoryHabits]);

    // 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢 Render 🟢🟢🟢🟢🟢🟢🟢🟢🟢🟢
    // Modal shown after adding a habit to proceed to habits page
    // Currently rendered at the bottom of the page
    const ProceedModal = ({ show, onHide }) => (
        <Modal show={show} onHide={onHide} className="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Proceed to Habits</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>"{pendingHabit}" has been added! Now let's find some habits for it.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={() => navigate('/habits')}>Proceed</Button>
            </Modal.Footer>
        </Modal>
    );

    const ConfirmModal = ({ show, onHide }) => (
        <Modal show={show} onHide={onHide} className="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title>Confirm Habit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You want to add "{pendingHabit && (typeof pendingHabit === 'string' ? pendingHabit : pendingHabit.text)}"?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={() => {
                    onHide();
                    handleAddHabit2(pendingHabit, category);
                }}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Choose Habits to Improve Your Life</title>
                <meta name="description" content="Select from a preconfigured list of Habits" />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0 bg-light">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'}`}>
                        <SideBar />
                    </div>
                </div>
                <div className="col flex-grow-1 px-4 pt-4">
                    <section className="mb-4">
                        <div className="block block-primary-header">
                            <div className="block-header">
                                <div className="d-flex align-items-center gap-3">
                                    <button
                                        className="btn btn-light btn-square-rounded shadow-sm"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#sidebar">
                                        <FontAwesomeIcon icon={faBars} />
                                    </button>
                                    <h2 className="mb-0 fw-bold">🌱 Habits</h2>
                                </div>
                                {user && (
                                    <div
                                        onClick={() => setShowCategoryModal(true)}
                                        className="category-selector d-flex align-items-center gap-2 p-2 rounded-3 bg-white shadow-sm">
                                        <FontAwesomeIcon icon={faLayerGroup} className="text-secondary" />
                                        <span className="fw-medium">{category || 'Select Category'}</span>
                                    </div>
                                )}
                            </div>
                            <div className="block-body">
                                <p className="mb-0">Build consistent routines that lead to lasting success</p>
                            </div>
                        </div>
                    </section>

                    <UserHabits
                        user={user}
                        category={category}
                        userHabits={userHabits}
                        loading={userHabitsLoading}
                        removeHabit={removeHabit}
                        fromGoal={fromGoal}
                        goalText={goalText}
                    />

                    <CustomHabitForm
                        user={user}
                        onAddHabit={handleAddHabit}
                        handleShowConfirmModal={handleShowConfirmModal}
                        selectedCategory={category}
                    />

                    <AIHabits
                        user={user}
                        category={category}
                        aiHabits={aiHabits}
                        loading={aiHabitsLoading}
                        handleAddHabit={handleAddHabit}
                    />

                    {/* Only show Popular Habits when not coming from a goal */}
                    {!fromGoal && (
                        <PopularHabits
                            user={user}
                            category={category}
                            allHabits={allHabits}
                            loading={allHabitsLoading}
                            handleAddHabit={handleAddHabit}
                            checkIfLinked={checkIfLinked}
                        />
                    )}

                    <Modal show={showCategoryModal} onHide={handleCloseModal} centered className="modal-lg">
                        <Modal.Header closeButton>
                            <Modal.Title className="fw-bold">Select a Habit Category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="text-muted mb-3">Choose a category to focus your habits and see relevant suggestions</p>
                            <div className="category-grid">
                                {HABIT_CATEGORIES.map(option => (
                                    <div
                                        key={option.value}
                                        onClick={() => handleCategorySelect(option.value)}
                                        className="block block-sm block-hover shadow-sm p-3 mb-3 text-center">
                                        <div className="mb-2">
                                            <FontAwesomeIcon icon={faLayerGroup} className="fs-3 text-secondary" />
                                        </div>
                                        <div className="fw-medium">{option.label}</div>
                                    </div>
                                ))}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="button button--outline-secondary" onClick={handleCloseModal}>Cancel</button>
                        </Modal.Footer>
                    </Modal>
                    <ConfirmModal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} habit={pendingHabit} onConfirm={confirmAdd} />
                    <TutorialModal show={showTutorialModal} onHide={() => setShowTutorialModal(false)} />
                    <ProceedModal show={false} onHide={() => { }} />
                </div>
            </div>
            <div className="row">
                <div className="col bg-white border-top border-dark">
                    <Footer />
                </div>
            </div>
        </div >
    );
};

export default Habits;
