// src/utils/goalOptions.js

export const HABIT_CATEGORIES = [
    { value: 'All', label: 'All Categories' },
    { value: 'Essential', label: 'Essential' },
    { value: 'Career', label: 'Career' },
    { value: 'Family', label: 'Family' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Fun', label: 'Fun' },
    { value: 'Health', label: 'Health' },
    { value: 'Relationship', label: 'Relationship' },
    { value: 'Development', label: 'Self-Development' },
    { value: 'Spiritual', label: 'Spiritual' }
];

export const GOAL_CATEGORIES = [
    { value: 'All', label: 'All Categories' },
    { value: 'Career', label: 'Career' },
    { value: 'Family', label: 'Family' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Fun', label: 'Fun' },
    { value: 'Health', label: 'Health' },
    { value: 'Relationship', label: 'Relationship' },
    { value: 'Development', label: 'Self-Development' },
    { value: 'Spiritual', label: 'Spiritual' }
];