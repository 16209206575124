import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTrash, faCalendarCheck, faRepeat, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { Link, } from "react-router-dom";

import '../../styles/styles.css';

const UserHabits = ({
    category,
    userHabits,
    loading,
    removeHabit,
    fromGoal,
    goalText,
    user,
}) => {
    // Show all habits if category is "All", otherwise filter by category
    const filteredUserHabits = category === 'All'
        ? userHabits
        : userHabits.filter(userHabit => userHabit?.habit?.category === category);

    if (loading) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '150px' }}>
                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                <p className="mt-2">Loading habits...</p>
            </div>
        );
    }

    return (
        <>
            {
                user ? (
                    <section className="mb-4">
                        <div className="block block-primary">
                            <div className="block-header">
                                <h4 className="mb-0 fw-bold">
                                    🌱 {fromGoal ? 'Supporting Habits for Goal' : `Your ${category === 'All' ? '' : category} Habits`}
                                </h4>
                            </div>
                            <div className="block-body">
                                {fromGoal && (
                                    <div className="goal-reference p-3 bg-light rounded-4 mb-3 border-start border-primary border-3">
                                        <h5 className="mb-0 fw-medium">{goalText}</h5>
                                    </div>
                                )}

                                {filteredUserHabits.length > 0 ? (
                                    <div className="row g-3">
                                        {filteredUserHabits.map(({ habit, _id }) => (
                                            <div key={habit._id} className="col-md-4 col-sm-6 col-12">
                                                <div className="inner-block inner-block--habit h-100">
                                                    <div className="inner-block__content p-3">
                                                        <div className="d-flex flex-wrap justify-content-between align-items-start mb-2">
                                                            <h5 className="fw-bold mb-1">{habit.headline}</h5>
                                                            <span className="badge bg-primary-light text-secondary rounded-pill ms-1 fs-sm">{habit.category}</span>
                                                        </div>
                                                        <div className="habit-info mt-2">
                                                            <div className="d-flex align-items-center text-muted small">
                                                                <FontAwesomeIcon icon={faCalendarCheck} className="me-2 flex-shrink-0" />
                                                                <span>Track daily progress on the Work page</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="inner-block__actions p-3 d-flex justify-content-end">
                                                        <button
                                                            className="button button--sm button--outline-danger"
                                                            onClick={() => removeHabit(_id)}
                                                            title="Delete habit"
                                                        >
                                                            <FontAwesomeIcon icon={faTrash} className="me-1" />
                                                            <span className="d-none d-sm-inline">Remove</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="empty-state p-4 text-center bg-light rounded-4">
                                        <p className="text-muted mb-0">No habits found{category !== 'All' ? ' in this category' : ''}. Try adding some!</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                ) : (
                <section className="mb-4">
                    <div className="block block-primary">
                        <div className="block-header">
                            <h4 className="mb-0 fw-bold">
                                🌱 Build Better Habits
                            </h4>
                        </div>
                        <div className="block-body">
                            <p className="lead mb-3 d-none d-md-block">Habits are the foundation of lasting change. Small, consistent actions lead to remarkable results.</p>
                            <p className="mb-3 d-block d-md-none">Small, consistent actions lead to remarkable results.</p>

                            <div className="row g-3 mb-3 d-none d-md-flex">
                                <div className="col-md-4">
                                    <div className="block block-sm">
                                        <div className="block-body p-3">
                                            <div className="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faRepeat} className="text-secondary me-2" />
                                                <h5 className="mb-0">Consistency Tracking</h5>
                                            </div>
                                            <p className="text-muted mb-0">Build streaks that motivate you</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="block block-sm">
                                        <div className="block-body p-3">
                                            <div className="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faChartLine} className="text-secondary me-2" />
                                                <h5 className="mb-0">Progress Insights</h5>
                                            </div>
                                            <p className="text-muted mb-0">Visualize your improvement</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="block block-sm">
                                        <div className="block-body p-3">
                                            <div className="d-flex align-items-center mb-2">
                                                <FontAwesomeIcon icon={faCalendarCheck} className="text-secondary me-2" />
                                                <h5 className="mb-0">Smart Reminders</h5>
                                            </div>
                                            <p className="text-muted mb-0">Never miss a habit again</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex flex-column flex-md-row justify-content-center gap-2 gap-md-3 mt-3">
                                <Link to="/register" className="button button--primary">Start Building Habits</Link>
                                <Link to="/login" className="button button--outline-primary">Log In</Link>
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default UserHabits;
