import React, { useState } from 'react';
import { HABIT_CATEGORIES } from '../../utils/goalOptions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const CustomHabitForm = ({ user, onAddHabit, handleShowConfirmModal, selectedCategory }) => {
    const [habitText, setHabitText] = useState('');
    const [habitCategory, setHabitCategory] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!habitText.trim()) return;

        // If "All" is selected, ensure a valid category is chosen from dropdown
        if (selectedCategory === 'All') {
            if (!habitCategory || habitCategory === 'All') {
                alert('Please select a valid category');
                return;
            }
            handleShowConfirmModal(habitText, habitCategory);
        } else {
            handleShowConfirmModal(habitText, selectedCategory);
        }
    };

    return (
        <>
            {user ? (
                <section className="mb-4">
                    <div className="block block-primary">
                        <div className="block-header">
                            <h4 className="mb-0 fw-bold">
                                🌱 Create Your Own Habit
                            </h4>
                        </div>
                        <div className="block-body">
                            <p className="text-muted mb-3">Define a consistent habit that will help you achieve your goals</p>
                            <form onSubmit={handleSubmit} className="custom-form">
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        placeholder="E.g., Run for 30 minutes every morning"
                                        className="form-control border-end-0 rounded-start-pill"
                                        value={habitText}
                                        onChange={(e) => setHabitText(e.target.value)}
                                        required
                                        style={{ height: '48px' }}
                                    />
                                    <button
                                        className="button button--primary rounded-end-pill px-4"
                                        type="submit"
                                        style={{ height: '48px' }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} className="button__icon" />
                                        Add Habit
                                    </button>
                                </div>

                                {selectedCategory === 'All' && (
                                    <div className="category-select-container mb-3">
                                        <label className="form-label text-muted">Select a category for this habit:</label>
                                        <select
                                            className="form-select rounded-pill"
                                            value={habitCategory}
                                            onChange={(e) => setHabitCategory(e.target.value)}
                                            required
                                        >
                                            <option value="">Choose category...</option>
                                            {HABIT_CATEGORIES.filter(cat => cat.value !== 'All').map(category => (
                                                <option key={category.value} value={category.value}>
                                                    {category.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                                <div className="form-text small text-muted">
                                    Effective habits are specific, realistic, and easy to track consistently.
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            ) : (
                <></>
            )}
        </>
    );
};

export default CustomHabitForm;
