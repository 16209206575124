import { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import SideBar from "../components/page/SideBar";
import Footer from "../components/page/Footer";
import { api } from "../api/api.js";
import { AuthContext } from "../context/AuthContext";
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser, faRedo, faUserCircle, faTrash, faShield, faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import { useTutorial } from '../contexts/TutorialContext';

const Profile = () => {
    const { user } = useContext(AuthContext);
    const { resetTutorial } = useTutorial();
    // Remove console logs for production code

    const [info, setInfo] = useState({
        username: user?.username || "",
        email: user?.email || "",
        password: "",
        confirmPassword: "",
        focusAreas: user?.focusAreas || [],
        challenges: user?.challenges || [],
        goalsLevel: user?.goalsLevel || "free"
    });
    const { loading, error, dispatch } = useContext(AuthContext);
    // const [subscriptions, setSubscriptions] = useState([]);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [focusAreaError, setFocusAreaError] = useState("");
    const [challengeError, setChallengeError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [focusArea, setFocusArea] = useState(user?.focusAreas[0] || "");
    const [challenge, setChallenge] = useState(user?.challenges[0] || "");

    // We'll implement real progress stats in the future

    const focusAreas = [
        "Career", "Self-Development", "Family", "Finance", "Fun", "Health", "Relationship", "Spiritual"
    ];

    const challenges = [
        'No Clarity', 'No Strategy', 'Procrastinate', 'Inconsistent', 'Fear of Failure',
        'Overwhelmed', 'No Resources', 'Distracted', 'Negative', 'No Time',
        'No Support', 'Unrealistic'
    ];

    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                try {
                    // const response = await api.get("/api/stripe/getsubscriptions");
                    // setSubscriptions(response.data);
                } catch (error) {
                    console.error("Error fetching subscriptions:", error);
                }
            }
        }
        fetchData();
    }, [user]);

    const handleChange = (e) => {
        setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value || "" }));
    };

    const handleFocusAreaChange = (area) => {
        setFocusArea(area);
        setFocusAreaError("");
    };

    const handleChallengeChange = (challenge) => {
        setChallenge(challenge);
        setChallengeError("");
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        console.log("Focus Area:", focusArea);
        console.log("Challenge:", challenge);
        if (info.password !== info.confirmPassword) {
            setPasswordError("Passwords do not match");
            return;
        }
        if (!focusArea) {
            setFocusAreaError("Please select a focus area.");
            return;
        }
        if (!challenge) {
            setChallengeError("Please select a challenge.");
            return;
        }
        setPasswordError("");
        try {
            const res = await api.put(`/api/auth/${user._id}`,
                {
                    username: info.username,
                    password: info.password,
                    focusAreas: [focusArea],
                    challenges: [challenge],
                    goalsLevel: info.goalsLevel
                }
            );
            console.log("res", res.data.entity);

            setInfo(res.data.entity);

            dispatch({ type: "LOGIN_PASS", payload: res.data.entity });
            setSuccessMessage("Profile updated successfully");
            setTimeout(() => {
                setSuccessMessage("");
            }, 5000);
        } catch (err) {
            dispatch({ type: "SET_ERROR", payload: err.response?.data?.message || "Could not update profile" });
        }
    };

    const handleDelete = async (e) => {
        console.log("in handleDelete");

        e.preventDefault();
        try {
            const res = await api.delete(`/api/user/${user._id}`);
            dispatch({ type: "LOGOUT", payload: res.data });
            navigate('/');
        } catch (err) {
            dispatch({ type: "SET_ERROR", payload: err.response?.data?.message || "Could not delete profile" });
        }
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <div className="container-fluid">
            <Helmet>
                <title>Profile - Goals & Habits Management</title>
                <meta name="description" content="Manage your Goals & Habits profile and subscriptions." />
            </Helmet>
            <div className="row bg-white sticky-top">
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0 bg-light">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'} border-end`}>
                        <SideBar />
                    </div>
                </div>
                <div className="col flex-grow-1 px-4 pt-4">
                    <section className="mb-4">
                        <div className="block block-primary-header">
                            <div className="block-header">
                                <div className="d-flex align-items-center gap-3">
                                    <button
                                        className="btn btn-light btn-square-rounded shadow-sm"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#sidebar">
                                        <FontAwesomeIcon icon={faBars} />
                                    </button>
                                    <h2 className="mb-0 fw-bold">👤 Profile</h2>
                                </div>
                            </div>
                            <div className="block-body">
                                <p className="mb-0">Manage your account settings and preferences</p>
                            </div>
                        </div>
                    </section>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">

                                {error && (
                                    <div className="alert alert-danger text-center" role="alert">
                                        {error}
                                    </div>
                                )}

                                {successMessage && (
                                    <div className="alert alert-success text-center" role="alert">
                                        {successMessage}
                                    </div>
                                )}

                                {/* Progress Summary Card - Commented out as requested */}
                                {/*
                                <div className="card shadow-sm rounded-3 mb-4 border-0">
                                    <div className="card-header py-3 bg-primary text-white">
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon icon={faChartLine} className="me-2" />
                                            <h4 className="m-0 fw-normal">Your Progress Summary</h4>
                                        </div>
                                    </div>
                                    <div className="card-body bg-light">
                                        <div className="row g-4">
                                            <div className="col-md-6">
                                                <div className="d-flex justify-content-between mb-2">
                                                    <h6 className="mb-0">Goals Progress</h6>
                                                    <span className="text-muted">{stats.completedGoals}/{stats.totalGoals}</span>
                                                </div>
                                                <ProgressBar
                                                    now={(stats.completedGoals/stats.totalGoals)*100}
                                                    variant="success"
                                                    className="mb-3"
                                                />

                                                <div className="d-flex justify-content-between mb-2">
                                                    <h6 className="mb-0">Habits Progress</h6>
                                                    <span className="text-muted">{stats.completedHabits}/{stats.totalHabits}</span>
                                                </div>
                                                <ProgressBar
                                                    now={(stats.completedHabits/stats.totalHabits)*100}
                                                    variant="info"
                                                    className="mb-3"
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="d-flex align-items-center mb-3">
                                                    <div className="p-3 me-3 bg-warning bg-opacity-25 rounded-circle">
                                                        <FontAwesomeIcon icon={faFire} className="text-warning" />
                                                    </div>
                                                    <div>
                                                        <h6 className="mb-0">Current Streak</h6>
                                                        <h4 className="mb-0 text-warning">{stats.currentStreak} days</h4>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="p-3 me-3 bg-primary bg-opacity-25 rounded-circle">
                                                        <FontAwesomeIcon icon={faMedal} className="text-primary" />
                                                    </div>
                                                    <div>
                                                        <h6 className="mb-0">Longest Streak</h6>
                                                        <h4 className="mb-0 text-primary">{stats.longestStreak} days</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                */}

                                {/* User Profile Card */}
                                <div className="block block-primary mb-4">
                                    <div className="block-header">
                                        <h4 className="mb-0 fw-bold">✏️ Update Profile</h4>
                                    </div>
                                    <div className="card-body">
                                        <form className="px-4 m-0">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="inner-block inner-block--account mb-3">
                                                        <div className="inner-block__content p-3">
                                                            <h5 className="fw-bold mb-3">Account Information</h5>
                                                            <div className="p-0 mb-3 form-group">
                                                                <div className="input-group">
                                                                    <span className="input-group-text bg-white"><FontAwesomeIcon icon={faEnvelope} className="text-muted" /></span>
                                                                    <input
                                                                        type="text"
                                                                        placeholder={user.email}
                                                                        id="email"
                                                                        value={info.email}
                                                                        className="form-control"
                                                                        disabled={true}
                                                                        aria-label="Email"
                                                                    />
                                                                </div>
                                                                <small className="text-muted">Your email address cannot be changed</small>
                                                            </div>
                                                            <div className="p-0 mb-3 form-group">
                                                                <div className="input-group">
                                                                    <span className="input-group-text bg-white"><FontAwesomeIcon icon={faUser} className="text-muted" /></span>
                                                                    <input
                                                                        onChange={handleChange}
                                                                        type="text"
                                                                        placeholder="Enter new username"
                                                                        id="username"
                                                                        value={info.username}
                                                                        className="form-control"
                                                                        aria-label="Username"
                                                                    />
                                                                </div>
                                                                <small className="text-muted">Choose a username that will be visible to others</small>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="inner-block inner-block--security">
                                                        <div className="inner-block__content p-3">
                                                            <h5 className="fw-bold mb-3">Security</h5>
                                                            <div className="p-0 mb-3 form-group">
                                                                <div className="input-group">
                                                                    <span className="input-group-text bg-white"><FontAwesomeIcon icon={faLock} className="text-muted" /></span>
                                                                    <input
                                                                        onChange={handleChange}
                                                                        type="password"
                                                                        placeholder="Enter new password"
                                                                        id="password"
                                                                        value={info.password}
                                                                        className="form-control"
                                                                        aria-label="Password"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="p-0 mb-3 form-group">
                                                                <div className="input-group">
                                                                    <span className="input-group-text bg-white"><FontAwesomeIcon icon={faShield} className="text-muted" /></span>
                                                                    <input
                                                                        onChange={handleChange}
                                                                        type="password"
                                                                        placeholder="Confirm new password"
                                                                        id="confirmPassword"
                                                                        value={info.confirmPassword}
                                                                        className="form-control"
                                                                        aria-label="Confirm Password"
                                                                    />
                                                                </div>
                                                                {passwordError && <div className="text-danger mt-2"><small>{passwordError}</small></div>}
                                                                <small className="text-muted">Leave blank if you don't want to change your password</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inner-block inner-block--focus mb-3">
                                                        <div className="inner-block__content p-3">
                                                            <h5 className="fw-bold mb-3">Edit Your Goal Focus Area</h5>
                                                            <div className="row g-3">
                                                                {focusAreas.map((area) => {
                                                                    const isSelected = focusArea === area;
                                                                    return (
                                                                        <div key={area} className="col-6 col-md-4">
                                                                            <button
                                                                                type="button"
                                                                                className={`button w-100 ${isSelected ? 'button--primary' : 'button--outline-primary'}`}
                                                                                onClick={() => handleFocusAreaChange(area)}
                                                                            >
                                                                                {area}
                                                                            </button>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            {focusAreaError && <div className="text-danger mt-2">{focusAreaError}</div>}
                                                        </div>
                                                    </div>

                                                    <div className="inner-block inner-block--challenge">
                                                        <div className="inner-block__content p-3">
                                                            <h5 className="fw-bold mb-3">Edit Your Common Challenge</h5>
                                                            <div className="row g-3">
                                                                {challenges.map((specificChallenge) => {
                                                                    const isSelected = challenge === specificChallenge;
                                                                    return (
                                                                        <div key={specificChallenge} className="col-6 col-md-4">
                                                                            <button
                                                                                type="button"
                                                                                className={`button w-100 ${isSelected ? 'button--primary' : 'button--outline-primary'}`}
                                                                                onClick={() => handleChallengeChange(specificChallenge)}
                                                                            >
                                                                                {specificChallenge}
                                                                            </button>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            {challengeError && <div className="text-danger mt-2">{challengeError}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center mt-4">
                                                <button
                                                    type="button"
                                                    disabled={loading}
                                                    onClick={handleUpdate}
                                                    className="button button--primary px-4"
                                                >
                                                    <FontAwesomeIcon icon={faUserCircle} className="button__icon" />
                                                    Save Changes
                                                </button>
                                                {loading && <div className="spinner-border spinner-border-sm ms-2" role="status"></div>}
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="block block-primary my-4">
                                    <div className="block-header">
                                        <h4 className="mb-0 fw-bold">⚙️ App Settings</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div>
                                                <h5 className="mb-0">Tutorial</h5>
                                                <p className="text-muted small mb-0">Restart the app tutorial</p>
                                            </div>
                                            <button
                                                onClick={() => {
                                                    resetTutorial();
                                                    navigate('/');
                                                }}
                                                className="button button--outline-primary"
                                            >
                                                <FontAwesomeIcon icon={faRedo} className="button__icon" />
                                                Restart Tutorial
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="block block-danger my-4">
                                    <div className="block-header">
                                        <h4 className="mb-0 fw-bold">⚠️ Delete Profile</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="alert alert-warning">
                                            <p className="mb-3">Deleting your profile will permanently remove all your data including goals, habits, and progress history.</p>
                                            <div className="text-center">
                                                <button
                                                    onClick={handleShowModal}
                                                    className="button button--danger"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} className="button__icon" />
                                                    Delete Profile
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Modal show={showModal} onHide={handleCloseModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Confirm Deletion</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        Are you sure you want to delete your profile? This action cannot be undone.
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseModal}>
                                            Cancel
                                        </Button>
                                        <Button variant="danger" onClick={handleDelete}>
                                            Delete
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col bg-white border-top border-dark">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Profile;
