import '../../../src/styles/styles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { memo } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

const SearchItem = ({
    item,
    linked,
    user,
    entity,
    loading,
    handleAdd,
    toastMessage
}) => {
    // Using linked directly instead of state since we're not changing it
    const isLinked = linked;

    const showToast = (message) => {
        toast(<CustomToastContent message={message} />, {
            position: toast.POSITION.BOTTOM_RIGHT,
            progressClassName: 'custom-toast-progress'
        });
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const CustomToastContent = ({ message }) => (
        <div className="custom-toast-content">
            <FontAwesomeIcon icon={faCheck} className="custom-toast-check" />
            <p>{message}</p>
        </div>
    );

    const SearchItemImage = memo(function SearchItemImage({ src }) {
        return <img src={src} decoding="sync" className="card-img-top" alt="" />;
    });

    return (
        <div className="col-md-4 col-sm-6 col-12">
            <div className="inner-block inner-block--goal inner-block--with-image h-100">
                {item.img && (
                    <div className="inner-block__image">
                        <SearchItemImage key={item.img} src={item.img} />
                    </div>
                )}
                <div className="inner-block__content p-2">
                    <div className="d-flex justify-content-between align-items-start mb-2">
                        <h5 className="fw-bold mb-0">{item.headline}</h5>
                        <span className="badge bg-primary-light text-secondary rounded-pill ms-2 fs-sm">{item.category}</span>
                    </div>
                </div>
                <div className="inner-block__actions justify-content-center mt-auto p-2">
                    {user ? (
                        <button
                            className="button button--primary"
                            disabled={loading}
                            onClick={() => {
                                handleAdd();
                                showToast(toastMessage);
                            }}
                        >
                            {isLinked ? `Add ${capitalizeFirstLetter(entity)}` : "Add Again"}
                        </button>
                    ) : (
                        <Link to="/login" className="button button--primary">
                            Login to add {capitalizeFirstLetter(entity)}
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchItem;