import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const CustomGoalForm = ({ user, onAddGoal, handleShowConfirmModal }) => {
    const [customGoal, setCustomGoal] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (customGoal.trim()) {
            handleShowConfirmModal(customGoal); // Call the confirm modal function with the input goal
        }
    };

    return (
        <>
            {user ? (
                <section className="mb-4">
                    <div className="block block-primary">
                        <div className="block-header">
                            <h4 className="mb-0 fw-bold">
                                🎯 Create Your Own Goal
                            </h4>
                        </div>
                        <div className="block-body">
                            <p className="text-muted mb-3">Define a specific, measurable goal that aligns with your aspirations</p>
                            <form onSubmit={handleSubmit} className="custom-form">
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        placeholder="E.g., Run a half marathon in 3 months"
                                        className="form-control border-end-0 rounded-start-pill"
                                        value={customGoal}
                                        onChange={(e) => setCustomGoal(e.target.value)}
                                        required
                                        style={{ height: '48px' }}
                                    />
                                    <button
                                        className="button button--primary rounded-end-pill px-4"
                                        type="submit"
                                        style={{ height: '48px' }}
                                    >
                                        <FontAwesomeIcon icon={faPlus} className="button__icon" />
                                        Add Goal
                                    </button>
                                </div>
                                <div className="form-text small text-muted">
                                    Great goals are specific, measurable, achievable, relevant, and time-bound (SMART).
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            ) : (
                <></>
            )}
        </>
    );
};

export default CustomGoalForm;