import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTutorial } from '../../contexts/TutorialContext';
import { AuthContext } from '../../context/AuthContext';

// Import tutorial steps data
import { tutorialSteps } from './tutorialSteps';

const TutorialBanner = () => {
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);
    const { tutorialState, setTutorialStep, setSubStep, toggleExplorationBanner, skipTutorial, completeTutorial, resetTutorial } = useTutorial();
    const { currentStep, currentSubStep, showExplorationBanner } = tutorialState;
    
    // Don't render the banner if no user is logged in
    if (!user) return null;

    // Skip the tutorial completely
    const handleSkip = () => {
        skipTutorial();
    };

    // Move from command to explore phase
    const handleExplore = () => {
        setSubStep('explore');
    };

    // Move to the next step in the tutorial
    const handleContinue = () => {
        if (currentSubStep === 'explore') {
            switch (currentStep) {
                case 'welcome':
                    setTutorialStep('navigation');
                    setSubStep('command');
                    break;
                case 'navigation':
                    setTutorialStep('goalCategories');
                    setSubStep('command');
                    navigate('/goals');
                    break;
                case 'goalCategories':
                    setTutorialStep('createGoal');
                    setSubStep('command');
                    navigate('/goals');
                    break;
                case 'createGoal':
                    setTutorialStep('updateProfile');
                    setSubStep('command');
                    navigate('/profile');
                    break;
                case 'updateProfile':
                    setTutorialStep('complete');
                    setSubStep('command');
                    break;
                case 'complete':
                    // Only complete the tutorial after showing the conclusion page
                    if (currentSubStep === 'explore') {
                        completeTutorial();
                        toggleExplorationBanner(false);
                    } else {
                        setSubStep('explore');
                    }
                    break;
                default:
                    break;
            }
        } else {
            setSubStep('explore');
        }
    };

    // If tutorial is completed, don't show anything
    if (tutorialState.completed) return null;

    // Get current step data
    const steps = tutorialSteps;

    // Check if current step exists
    if (!steps[currentStep]) return null;

    // Get current step and sub-step data
    const currentStepData = steps[currentStep];

    // Handle case where a step doesn't have an explore phase
    if (currentSubStep === 'explore' && !currentStepData.explore) {
        // If we're in the welcome step and it doesn't have an explore phase,
        // automatically move to the next step
        if (currentStep === 'welcome') {
            setTutorialStep('navigation');
            setSubStep('command');
            return null;
        }
    }

    const currentSubStepData = currentStepData[currentSubStep];

    // Command phase - show the banner at the top
    if (currentSubStep === 'command' && showExplorationBanner) {
        return (
            <div
                className="alert alert-primary fade show position-fixed top-0 start-50 translate-middle-x mt-2"
                style={{
                    zIndex: 1050,
                    width: '90%',
                    maxWidth: '600px',
                    padding: '1.5rem',
                    position: 'relative'
                }}
                role="alert"
            >
                <button
                    type="button"
                    className="btn-close"
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px'
                    }}
                    onClick={handleSkip}
                    aria-label="Close"
                ></button>
                <h4 className="alert-heading mb-2">{currentSubStepData.title}</h4>
                {currentSubStepData.description.includes('\n\n') ? (
                    <div className="mb-3">
                        {currentSubStepData.description.split('\n\n').map((paragraph, index) => {
                            if (paragraph.match(/^\d+\./) !== null) {
                                return (
                                    <div key={index} className="mb-2">
                                        <strong>{paragraph.split(':')[0]}:</strong> {paragraph.split(':')[1]}
                                    </div>
                                );
                            } else {
                                return <p key={index} className="mb-2">{paragraph}</p>;
                            }
                        })}
                    </div>
                ) : (
                    <p className="mb-3">{currentSubStepData.description}</p>
                )}

                {currentSubStepData.sections && (
                    <div className="mb-4">
                        {currentSubStepData.sections.map((section, index) => (
                            <div key={index} className="mb-2">
                                <h5 className="mb-1">{section.name}</h5>
                                <p className="ms-2 small">{section.description}</p>
                            </div>
                        ))}
                    </div>
                )}

                <div className="d-flex gap-2 justify-content-between">
                    {currentStep === 'welcome' ? (
                        <>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={handleSkip}
                                style={{ width: '160px' }}
                            >
                                Skip Tutorial
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleExplore}
                                style={{ width: '160px' }}
                            >
                                Start Tutorial →
                            </button>
                        </>
                    ) : currentStep === 'complete' ? (
                        <>
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => {
                                    resetTutorial();
                                    navigate('/');
                                }}
                                style={{ width: '160px' }}
                            >
                                Restart Tutorial
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={completeTutorial}
                                style={{ width: '160px' }}
                            >
                                Complete Tutorial
                            </button>
                        </>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleExplore}
                            style={{ width: '160px' }}
                        >
                            Explore →
                        </button>
                    )}
                </div>
            </div>
        );
    }

    // Explore phase - show a minimized modal with just continue and exit buttons
    if (currentSubStep === 'explore') {
        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',  // Allow clicking through the overlay
                zIndex: 9999
            }}>
                <div
                    className="alert alert-primary fade show position-fixed bottom-0 end-0 mb-4 me-4"
                    style={{
                        zIndex: 1050,
                        width: 'auto',
                        minWidth: '220px',
                        padding: '0.75rem 2.5rem 0.75rem 0.75rem', // Added right padding for X button
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        position: 'relative',
                        pointerEvents: 'auto'  // Make the buttons clickable
                    }}
                >
                    <button
                        type="button"
                        className="btn-close"
                        style={{
                            position: 'absolute',
                            top: '8px',
                            right: '8px',
                            zIndex: 2 // Ensure it's above other content
                        }}
                        onClick={handleSkip}
                        aria-label="Close"
                    ></button>

                    {currentStep === 'complete' ? (
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => {
                                    resetTutorial();
                                    navigate('/');
                                }}
                            >
                                Restart Tutorial
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={completeTutorial}
                            >
                                Complete Tutorial
                            </button>
                        </div>
                    ) : (
                        <div className="d-flex align-items-center justify-content-center">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleContinue}
                                style={{ marginRight: '8px' }} // Add margin to keep away from X button
                            >
                                Continue Tutorial →
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return null;
};

export default TutorialBanner;
