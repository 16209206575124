const moment = require('moment'); // Install with: npm install moment


export const minute = 60 * 1000;
export const hour = 60 * minute;
export const day = 24 * hour;
export const week = 7 * day;
export const year = 365 * day;

// export const todayStart = () => {
//     console.log("theDateHelper: todayStart");
//     var now = new Date().toISOString();
//     const temp = now.substring(0, 10) + "T00:00:00Z"
//     const tempDate = new Date(temp);
//     console.log("tempDate = " + tempDate);
//     return tempDate;
// }

export function isToday(dateStr) {
    return dateStr === getToday();
}
export const getToday = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to midnight local time
    return today.toISOString().split('T')[0]; // Return date in YYYY-MM-DD format
};

export function isFuture(dateStr, days) {
    const incrementedDate = incrementDate(dateStr, days);
    return incrementedDate > getToday();
}

export function incrementDate(dateStr, days) {
    return moment.utc(dateStr, 'YYYY-MM-DD').add(days, 'days').format('YYYY-MM-DD');
}

// export const todayStart = () => {
//     const today = new Date();
//     today.setHours(0, 0, 0, 0);
//     return today;
// };

// export const daysBetween = (startDate, endDate) => {
//     console.log("theDateHelper: daysBetween");
//     // The number of milliseconds in one day
//     const ONE_DAY = 1000 * 60 * 60 * 24;

//     // Convert both dates to milliseconds
//     const startDateMs = startDate.getTime();
//     const endDateMs = endDate.getTime();

//     // Calculate the difference in milliseconds
//     const differenceMs = Math.abs(endDateMs - startDateMs);

//     // Convert back to days and return
//     return Math.round(differenceMs / ONE_DAY);
// };



// // Convert a date string from YYYY-MM-DD to ISO string
// export const toISOString = (dateString) => {
//     console.log("theDateHelper: toISOString");
//     const date = new Date(dateString); // Create a Date object from the date string
//     return date.toISOString(); // Convert to ISO string
// };

// // Convert an ISO string to a date string in YYYY-MM-DD format
// export const fromISOString = (isoString) => {
//     console.log("theDateHelper: fromISOString");
//     const date = new Date(isoString); // Create a Date object from the ISO string
//     const year = date.getUTCFullYear();
//     const month = `0${date.getUTCMonth() + 1}`.slice(-2); // Month is 0-indexed
//     const day = `0${date.getUTCDate()}`.slice(-2);
//     return `${year}-${month}-${day}`; // Format as YYYY-MM-DD
// };
// export const formatDateToUS = (dateString) => {
//     console.log("theDateHelper: formatDateToUS");
//     const date = new Date(dateString);
//     const month = `0${date.getUTCMonth() + 1}`.slice(-2);
//     const day = `0${date.getUTCDate()}`.slice(-2);
//     const year = date.getUTCFullYear();
//     return `${month}/${day}/${year}`;
// }

// export const startOfDate = (date) => {
//     console.log("theDateHelper: startOfDate");
//     const tempDate = new Date(date); // Create a Date object from the input date
//     tempDate.setHours(0, 0, 0, 0); // Set to local midnight
//     return tempDate;
// }



// export const startOfDate = (date) => {
//     let temp = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
//     return temp;
// }
// export const startOfDate = (date) => {
//     var now = new Date(date).toISOString();
//     const temp = now.substring(0, 10) + "T00:00:00Z"
//     const tempDate = new Date(temp);
//     return tempDate;
// }


// export const removeDateTime = (date) => {
//     console.log("***date is " + date);
//     return date.split('T')[0];
// }

// export const lastXDays = (n, array) => {
//     const toDate = todayStart().getTime();
//     const fromDate = toDate - (n * day);
//     // console.log("from date is " + new Date(fromDate));
//     // console.log("n is " + n);
//     let count = 0;
//     for (const x of array) {
//         if (between(fromDate, new Date(x).getTime(), toDate)) {
//             count++;
//         }
//     };

//     return count;
// }

// export const formatToISOString = (date) => {
//     const newDate = new Date(date);
//     return newDate.toISOString();
// };


// export const lastXDaysNew = (n, array) => {

//     const temp = 5;
//     // console.log("n is " + n);
//     // console.log("temp is " + temp);
//     // console.log("array is " + array);

//     return temp;
// }

// export const inDateArray = (theDate, array) => {
//     const contains = array.some(date => new Date(date).getTime() === theDate.getTime());
//     return contains;
// }

// export const between = (from, date, to) => {
//     // console.log("from is " + from + " date is " + date + " to is " + to)
//     const temp = ((from <= date) && (date <= to));
//     // console.log("temp is " + temp);
//     return temp;
// }